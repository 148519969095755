/**
 * @generated SignedSource<<a1fb94cd406f1afa411c863817864725>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type BotEmojiUse = "none" | "often" | "some" | "%future added value";
export type BotKind = "brain_search" | "chat" | "copilot" | "inline_generation" | "%future added value";
export type BotPersonality = "friendly" | "professional" | "sassy" | "scholarly" | "%future added value";
export type BotStatus = "active" | "paused" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useChatBotFragment$data = {
  readonly chatBot: {
    readonly confidence: number;
    readonly embeddingsIndexName: string;
    readonly embeddingsNamespace: string;
    readonly emojiUse: BotEmojiUse;
    readonly id: string;
    readonly kind: BotKind;
    readonly name: string;
    readonly personality: BotPersonality;
    readonly status: BotStatus;
    readonly streamChatUserId: string | null;
    readonly temperature: number;
  } | null;
  readonly " $fragmentType": "useChatBotFragment";
};
export type useChatBotFragment$key = {
  readonly " $data"?: useChatBotFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"useChatBotFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useChatBotFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Bot",
      "kind": "LinkedField",
      "name": "chatBot",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "personality",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "embeddingsIndexName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "embeddingsNamespace",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "temperature",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "confidence",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "emojiUse",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kind",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "streamChatUserId",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "e28473f4b3bc1fb59749e0b02036b54a";

export default node;
