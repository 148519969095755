import { BadgeFragment$data } from "@/admin/experiences/badges/__generated__/BadgeFragment.graphql"
import {
  CreatePathwayInput,
  useCreatePathwayFormMutation,
} from "@/admin/pathways/hooks/__generated__/useCreatePathwayFormMutation.graphql"
import FormStore, { useFormStore } from "@/core/form/store/FormStore"
import { GlobalID } from "@/relay/RelayTypes"
import { useTheme } from "@material-ui/core"
import { observable } from "mobx"
import { graphql } from "relay-runtime"

export type CreatePathwayFormInitialState = CreatePathwayInput & {
  productCache: Record<
    GlobalID,
    { name: string; badge: Omit<BadgeFragment$data, " $fragmentType"> }
  >
}

export type CreatePathwayFormState = FormStore<
  CreatePathwayFormInitialState,
  useCreatePathwayFormMutation
>

export default function useCreatePathwayForm(): CreatePathwayFormState {
  const theme = useTheme()
  const form = useFormStore<useCreatePathwayFormMutation, CreatePathwayFormInitialState>(
    graphql`
      mutation useCreatePathwayFormMutation($input: CreatePathwayInput!) {
        response: createPathway(input: $input) {
          node {
            id
            slug
            status
            ...PathwayCardFragment
            ...MyExperiencesListItemFragment
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      name: "",
      description: "",
      badge: {
        kind: "icon",
        color: theme.palette.primary.main,
        icon: null,
      },
      registrationAvailability: "public",
      pathwayGroups: [
        {
          title: `Pathway Group 1`,
          productIds: observable.array([]),
        },
      ],
      productCache: {},
    }
  )
  return form
}
