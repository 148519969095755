import ChatChannelSettingsModal from "@/apps/list/app/chat/ChatChannelSettingsModal"
import CollectionAppSettingsModal from "@/apps/list/app/collections/CollectionAppSettingsModal"
import ContentAppSetupModal from "@/apps/list/app/content/modal/ContentAppSetupModal"
import EventsAppSetupModal from "@/apps/list/app/events/EventsAppSetupModal"
import FeedAppSetupModal from "@/apps/list/app/feed/modal/FeedAppSetupModal"
import GroupDirectoryAppSettingsModal from "@/apps/list/app/group-directory/GroupDirectoryAppSettingsModal"
import LinkAppSetupModal from "@/apps/list/app/link/modal/LinkAppSetupModal"
import CustomDiscoAppsList from "@/apps/list/CustomDiscoAppsList"
import IntegrationAppsList from "@/apps/list/IntegrationAppsList"
import { useActiveAppModal } from "@/apps/util/activeAppModalContext"
import { useAppLevel } from "@/apps/util/appLevelContext"
import Relay from "@/relay/relayUtils"
import { DiscoButton, DiscoModal } from "@disco-ui"
import DiscoTabs from "@disco-ui/tabs/DiscoTabs"
import React, { useState } from "react"

interface AddAppButtonProps {
  customTitle?: string | null
  hideChannelApp?: boolean
  children: React.FC<{
    onClick(): void
  }>
}

type Tab = "disco-apps" | "embeds"
const TEST_ID = "AddAppModal"

const AddAppButton: React.FC<AddAppButtonProps> = ({
  customTitle,
  hideChannelApp,
  children,
}) => {
  const { openModalState, setOpenModalState, closeModal } = useActiveAppModal()
  const { product } = useAppLevel()
  const [selectedTab, setSelectedTab] = useState<Tab>("disco-apps")

  return (
    <>
      {React.isValidElement(children) ? (
        <DiscoButton onClick={() => openModal()}>{children}</DiscoButton>
      ) : (
        React.createElement(children, { onClick: openModal })
      )}

      <DiscoModal
        isOpen={openModalState?.kind === "add-app"}
        onClose={closeModal}
        testid={TEST_ID}
        modalContentLabel={"Add App"}
        title={product ? `Add App to ${customTitle || product.name}` : "Add App"}
        width={"740px"}
        subtabs={
          <DiscoTabs
            testid={TEST_ID}
            routes={[
              {
                label: "Disco Apps",
                onClick: () => setSelectedTab("disco-apps"),
                testid: "disco-apps",
                active: selectedTab === "disco-apps",
              },
              {
                label: "Embeds",
                onClick: () => setSelectedTab("embeds"),
                testid: "embeds",
                active: selectedTab === "embeds",
              },
            ]}
          />
        }
        body={
          selectedTab === "disco-apps" ? (
            <CustomDiscoAppsList hideChannelApp={hideChannelApp} />
          ) : (
            <IntegrationAppsList />
          )
        }
      />

      {renderSetupModal()}
    </>
  )

  function openModal() {
    setOpenModalState({ kind: "add-app" })
  }

  /* eslint-disable complexity */
  function renderSetupModal() {
    // If openModalState.id exists, should not open the create modal
    if (openModalState?.id) return null

    switch (openModalState?.kind) {
      case "chat_channel":
        return (
          <ChatChannelSettingsModal testid={TEST_ID} productLevel={Boolean(product)} />
        )
      case "collection":
        return <CollectionAppSettingsModal testid={"CollectionAppSettings"} />
      case "posts":
        return <FeedAppSetupModal testid={TEST_ID} />
      case "content":
        return <ContentAppSetupModal testid={TEST_ID} />
      case "link":
      case "product_link":
        return <LinkAppSetupModal testid={TEST_ID} />
      case "organization_events":
        return <EventsAppSetupModal testid={TEST_ID} />
      case "group_directory":
        return <GroupDirectoryAppSettingsModal testid={TEST_ID} />
    }
    return null
  }
}

const AddAppButtonSkeleton: React.FC = () => null

export default Relay.withSkeleton<AddAppButtonProps>({
  component: AddAppButton,
  skeleton: AddAppButtonSkeleton,
})
