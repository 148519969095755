/**
 * @generated SignedSource<<e855a77cdcb8f141c35acde2168be644>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type MemberGroupKind = "custom" | "default" | "role" | "test_user" | "%future added value";
export type MemberGroupRole = "admin" | "instructor" | "manager" | "owner" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type MemberGroupAvatarFragment$data = {
  readonly avatarURL: string | null;
  readonly color: string;
  readonly kind: MemberGroupKind;
  readonly name: string;
  readonly product: {
    readonly id: string;
    readonly name: string;
  } | null;
  readonly role: MemberGroupRole | null;
  readonly " $fragmentType": "MemberGroupAvatarFragment";
};
export type MemberGroupAvatarFragment$key = {
  readonly " $data"?: MemberGroupAvatarFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"MemberGroupAvatarFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MemberGroupAvatarFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "avatarURL",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "color",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "product",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "role",
      "storageKey": null
    }
  ],
  "type": "MemberGroup",
  "abstractKey": null
};
})();

(node as any).hash = "c7a6fde7ef11e58d07bfee5cdc0c7eaf";

export default node;
