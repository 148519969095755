/**
 * @generated SignedSource<<f836d1067984eaed1c0a4bcbf540cadc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AdminContentLibraryItemUsagesQuery$variables = {
  after?: string | null;
  first: number;
  id: string;
};
export type AdminContentLibraryItemUsagesQuery$data = {
  readonly content: {
    readonly id?: string;
    readonly " $fragmentSpreads": FragmentRefs<"AdminContentLibraryItemUsages_PaginationFragment">;
  } | null;
};
export type AdminContentLibraryItemUsagesQuery = {
  response: AdminContentLibraryItemUsagesQuery$data;
  variables: AdminContentLibraryItemUsagesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Badge",
  "kind": "LinkedField",
  "name": "badge",
  "plural": false,
  "selections": [
    (v8/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "color",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "icon",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "emoji",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mediaUrl",
      "storageKey": null
    },
    (v4/*: any*/)
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "customAppTitle",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entity",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AdminContentLibraryItemUsagesQuery",
    "selections": [
      {
        "alias": "content",
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              {
                "args": (v5/*: any*/),
                "kind": "FragmentSpread",
                "name": "AdminContentLibraryItemUsages_PaginationFragment"
              }
            ],
            "type": "Content",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AdminContentLibraryItemUsagesQuery",
    "selections": [
      {
        "alias": "content",
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v4/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "ContentUsageNodeConnection",
                "kind": "LinkedField",
                "name": "usages",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContentUsageNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ContentUsage",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Product",
                            "kind": "LinkedField",
                            "name": "product",
                            "plural": false,
                            "selections": [
                              (v7/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "registrationAvailability",
                                "storageKey": null
                              },
                              (v9/*: any*/),
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "slug",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProductApp",
                            "kind": "LinkedField",
                            "name": "productApp",
                            "plural": false,
                            "selections": [
                              (v8/*: any*/),
                              (v10/*: any*/),
                              (v9/*: any*/),
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Content",
                            "kind": "LinkedField",
                            "name": "module",
                            "plural": false,
                            "selections": [
                              (v7/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ContentUsageNodeConnection",
                                "kind": "LinkedField",
                                "name": "usages",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ContentUsageNodeEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "ContentUsage",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v4/*: any*/),
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "isDefaultCollectionModule",
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Collection",
                                            "kind": "LinkedField",
                                            "name": "collection",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "ProductApp",
                                                "kind": "LinkedField",
                                                "name": "app",
                                                "plural": false,
                                                "selections": [
                                                  (v10/*: any*/),
                                                  (v9/*: any*/),
                                                  (v4/*: any*/)
                                                ],
                                                "storageKey": null
                                              },
                                              (v4/*: any*/)
                                            ],
                                            "storageKey": null
                                          },
                                          (v11/*: any*/),
                                          (v12/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v11/*: any*/),
                          (v12/*: any*/),
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfoObjectType",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v5/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "AdminContentLibraryItemUsages__usages",
                "kind": "LinkedHandle",
                "name": "usages"
              }
            ],
            "type": "Content",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3462610a785da7cd2a382408844a2ee4",
    "id": null,
    "metadata": {},
    "name": "AdminContentLibraryItemUsagesQuery",
    "operationKind": "query",
    "text": "query AdminContentLibraryItemUsagesQuery(\n  $id: ID!\n  $first: Int!\n  $after: String\n) {\n  content: node(id: $id) {\n    __typename\n    ... on Content {\n      id\n      ...AdminContentLibraryItemUsages_PaginationFragment_2HEEH6\n    }\n    id\n  }\n}\n\nfragment AdminContentLibraryItemUsages_PaginationFragment_2HEEH6 on Content {\n  usages(first: $first, after: $after) {\n    edges {\n      node {\n        id\n        ...ContentUsageInstanceItem_ContentUsageFragment\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      startCursor\n      endCursor\n      hasNextPage\n      hasPreviousPage\n    }\n  }\n  id\n}\n\nfragment BadgeFragment on Badge {\n  kind\n  color\n  icon\n  emoji\n  mediaUrl\n}\n\nfragment ContentUsageInstanceItem_ContentUsageFragment on ContentUsage {\n  id\n  product {\n    name\n    registrationAvailability\n    badge {\n      ...BadgeFragment\n      id\n    }\n    id\n  }\n  productApp {\n    kind\n    customAppTitle\n    badge {\n      ...BadgeFragment\n      id\n    }\n    id\n  }\n  module {\n    name\n    usages {\n      edges {\n        node {\n          id\n          isDefaultCollectionModule\n          collection {\n            app {\n              customAppTitle\n              badge {\n                ...BadgeFragment\n                id\n              }\n              id\n            }\n            id\n          }\n        }\n      }\n    }\n    id\n  }\n  entity\n  ...ContentUsageUtils_useContentUsagePathContentUsageFragment\n}\n\nfragment ContentUsageUtils_useContentUsagePathContentUsageFragment on ContentUsage {\n  id\n  entity\n  entityId\n  product {\n    slug\n    id\n  }\n  productApp {\n    kind\n    id\n  }\n  module {\n    usages {\n      edges {\n        node {\n          id\n          entity\n          entityId\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "76f8b5285ebf66fc366ed7340df9d362";

export default node;
