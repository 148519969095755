import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { isBrainSearchPath } from "@/core/route/util/routeUtils"
import Relay from "@/relay/relayUtils"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import { useBrainSearchBotFragment$key } from "./__generated__/useBrainSearchBotFragment.graphql"

function useBrainSearchBot() {
  const activeOrganization = useActiveOrganization()!
  const organization = useFragment<useBrainSearchBotFragment$key>(
    graphql`
      fragment useBrainSearchBotFragment on Organization {
        brainSearchBot {
          id
          name
          status
          enableWebSearch
          enableMedia
          enableSources
          enableSuggestedPrompts
          suggestedPrompts {
            edges {
              node {
                id
                prompt
              }
            }
          }
        }
      }
    `,
    activeOrganization
  )
  if (!organization.brainSearchBot) return null

  const suggestedPrompts = Relay.connectionToArray(
    organization.brainSearchBot?.suggestedPrompts
  )

  return {
    ...organization.brainSearchBot,
    suggestedPrompts: suggestedPrompts.map((p) => p.prompt),
    isPreviewing:
      organization.brainSearchBot.status !== "active" &&
      isBrainSearchPath(location.pathname),
  }
}

export default useBrainSearchBot
