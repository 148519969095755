import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import UnsavedChangesModalProvider, {
  useInitUnsavedChangesModalContext,
  useUnsavedChangesModalContext,
} from "@/core/context/UnsavedChangesModalProvider"
import { GlobalID } from "@/relay/RelayTypes"
import { DiscoDrawer } from "@disco-ui"
import lazyWithRetry from "@utils/lazy/lazyWithRetry"

const WebhookEndpointDrawerContent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "webhook-endpoint-drawer-content" */ "@/admin/webhooks/WebhookEndpointDrawerContent"
    )
)

function WebhookEndpointDrawer() {
  const drawer = useWebhookEndpointDrawer()
  const modal = useInitUnsavedChangesModalContext()

  return (
    <UnsavedChangesModalProvider {...modal}>
      <DiscoDrawer
        // creation drawer is lg, edit drawer is xl
        size={drawer.params.weID === "new" ? "large" : "xl"}
        open={drawer.isOpen}
        onClose={drawer.handleClose}
        padding={0}
      >
        {drawer.isOpen && <WebhookEndpointDrawerContent />}
      </DiscoDrawer>
    </UnsavedChangesModalProvider>
  )
}

export function useWebhookEndpointDrawer() {
  const drawer = useGlobalDrawer("webhookEndpoint")
  const modal = useUnsavedChangesModalContext()

  return {
    params: drawer.params,
    isOpen: drawer.isOpen,
    close: drawer.close,
    openCreateForm: () => drawer.open({ weID: "new" }),
    openEditForm: (webhookEndpointId: GlobalID) =>
      drawer.open({ weID: webhookEndpointId, weTab: "settings" }),
    openLogs: (webhookEndpointId: GlobalID) =>
      drawer.open({ weID: webhookEndpointId, weTab: "send-logs" }),
    handleClose() {
      modal.handleLeave({
        onLeave: () => {
          drawer.close()
        },
      })
    },
  }
}

export default WebhookEndpointDrawer
