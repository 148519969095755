import useChatBot from "@/admin/integrations/chat-bot/util/useChatBot"
import useFeatureFlags from "@utils/hook/useFeatureFlags"
import useHasEntitlement from "@utils/hook/useHasEntitlement"

export default function useCanAccessTrainingSources() {
  const { webCrawl, brainSearch } = useFeatureFlags()
  const hasInlineGeneration = useHasEntitlement("ai_content_generation")
  const chatBot = useChatBot()

  return webCrawl && Boolean(chatBot || brainSearch || hasInlineGeneration)
}
