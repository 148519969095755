/**
 * @generated SignedSource<<7122efba998dcda8c7bcb2cece98f938>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AssetTrackKind = "subtitles" | "%future added value";
export type LanguageCode = "aa" | "ab" | "ae" | "af" | "ak" | "am" | "an" | "ar" | "as" | "av" | "ay" | "az" | "ba" | "be" | "bg" | "bi" | "bm" | "bn" | "bo" | "br" | "bs" | "ca" | "ce" | "ch" | "co" | "cr" | "cs" | "cu" | "cv" | "cy" | "da" | "de" | "dv" | "dz" | "ee" | "el" | "en" | "eo" | "es" | "et" | "eu" | "fa" | "ff" | "fi" | "fj" | "fo" | "fr" | "fy" | "ga" | "gd" | "gl" | "gn" | "gu" | "gv" | "ha" | "he" | "hi" | "ho" | "hr" | "ht" | "hu" | "hy" | "hz" | "ia" | "id" | "ie" | "ig" | "ii" | "ik" | "io" | "is" | "it" | "iu" | "ja" | "jv" | "ka" | "kg" | "ki" | "kj" | "kk" | "kl" | "km" | "kn" | "ko" | "kr" | "ks" | "ku" | "kv" | "kw" | "ky" | "la" | "lb" | "lg" | "li" | "ln" | "lo" | "lt" | "lu" | "lv" | "mg" | "mh" | "mi" | "mk" | "ml" | "mn" | "mr" | "ms" | "mt" | "my" | "na" | "nb" | "nd" | "ne" | "ng" | "nl" | "nn" | "no" | "nr" | "nv" | "ny" | "oc" | "oj" | "om" | "or" | "os" | "pa" | "pi" | "pl" | "ps" | "pt" | "qu" | "rm" | "rn" | "ro" | "ru" | "rw" | "sa" | "sc" | "sd" | "se" | "sg" | "si" | "sk" | "sl" | "sm" | "sn" | "so" | "sq" | "sr" | "ss" | "st" | "su" | "sv" | "sw" | "ta" | "te" | "tg" | "th" | "ti" | "tk" | "tl" | "tn" | "to" | "tr" | "ts" | "tt" | "tw" | "ty" | "ug" | "uk" | "ur" | "uz" | "ve" | "vi" | "vo" | "wa" | "wo" | "xh" | "yi" | "yo" | "za" | "zh" | "zu" | "%future added value";
export type CreateAssetTrackInput = {
  kind: AssetTrackKind;
  languageCode: LanguageCode;
  trackAssetId: string;
  videoAssetId: string;
};
export type CreateAssetTrackFormMutation$variables = {
  connections: ReadonlyArray<string>;
  input: CreateAssetTrackInput;
};
export type CreateAssetTrackFormMutation$data = {
  readonly response: {
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
    readonly node: {
      readonly id: string;
      readonly languageCode: LanguageCode | null;
      readonly name: string;
      readonly url: string | null;
      readonly videoAsset: {
        readonly hasSubtitles: boolean;
        readonly id: string;
      };
      readonly " $fragmentSpreads": FragmentRefs<"AssetTracksListItemFragment">;
    } | null;
  };
};
export type CreateAssetTrackFormMutation = {
  response: CreateAssetTrackFormMutation$data;
  variables: CreateAssetTrackFormMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "languageCode",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasSubtitles",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "ValidationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateAssetTrackFormMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v2/*: any*/),
        "concreteType": "CreateAssetTrackResponse",
        "kind": "LinkedField",
        "name": "createAssetTrack",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AssetTrack",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Asset",
                "kind": "LinkedField",
                "name": "videoAsset",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "AssetTracksListItemFragment"
              }
            ],
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CreateAssetTrackFormMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v2/*: any*/),
        "concreteType": "CreateAssetTrackResponse",
        "kind": "LinkedField",
        "name": "createAssetTrack",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AssetTrack",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Asset",
                "kind": "LinkedField",
                "name": "videoAsset",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": "notAutoGeneratedSubtitleTracks",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "isAutoGenerated",
                        "value": false
                      }
                    ],
                    "concreteType": "AssetTrackNodeConnection",
                    "kind": "LinkedField",
                    "name": "subtitleTracks",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AssetTrackNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AssetTrack",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v6/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "subtitleTracks(isAutoGenerated:false)"
                  },
                  {
                    "alias": "deleteSubtitleTracks",
                    "args": null,
                    "concreteType": "AssetTrackNodeConnection",
                    "kind": "LinkedField",
                    "name": "subtitleTracks",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "totalCount",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isAutoGenerated",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "muxAssetTrackStatus",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Asset",
                "kind": "LinkedField",
                "name": "trackAsset",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "videoAssetId",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "appendNode",
            "key": "",
            "kind": "LinkedHandle",
            "name": "node",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              },
              {
                "kind": "Literal",
                "name": "edgeTypeName",
                "value": "AssetTrackNodeEdge"
              }
            ]
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "21a999faef559f2c7f8c09afd6934643",
    "id": null,
    "metadata": {},
    "name": "CreateAssetTrackFormMutation",
    "operationKind": "mutation",
    "text": "mutation CreateAssetTrackFormMutation(\n  $input: CreateAssetTrackInput!\n) {\n  response: createAssetTrack(input: $input) {\n    node {\n      id\n      url\n      name\n      languageCode\n      videoAsset {\n        id\n        hasSubtitles\n      }\n      ...AssetTracksListItemFragment\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n\nfragment AssetTrackFormLanguageSectionFragment on Asset {\n  notAutoGeneratedSubtitleTracks: subtitleTracks(isAutoGenerated: false) {\n    edges {\n      node {\n        id\n        languageCode\n      }\n    }\n  }\n}\n\nfragment AssetTrackStatusChipFragment on AssetTrack {\n  muxAssetTrackStatus\n  isAutoGenerated\n}\n\nfragment AssetTracksListItemFragment on AssetTrack {\n  id\n  url\n  name\n  languageCode\n  isAutoGenerated\n  muxAssetTrackStatus\n  trackAsset {\n    name\n    id\n  }\n  ...AssetTrackStatusChipFragment\n  ...UpdateAssetTrackFormFragment\n  ...DeleteAssetTrackButtonFragment\n}\n\nfragment DeleteAssetTrackButtonFragment on AssetTrack {\n  id\n  videoAsset {\n    id\n    deleteSubtitleTracks: subtitleTracks {\n      totalCount\n    }\n  }\n}\n\nfragment UpdateAssetTrackFormFragment on AssetTrack {\n  id\n  languageCode\n  videoAssetId\n  videoAsset {\n    ...AssetTrackFormLanguageSectionFragment\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "45d197689a89511b9cb6430bc3321ba7";

export default node;
