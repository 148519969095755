/**
 * @generated SignedSource<<7e5353f920b44cfcdbdc2a8015285ea7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AdminContentLibraryItemUsages_ContentFragment$data = {
  readonly id: string;
  readonly " $fragmentType": "AdminContentLibraryItemUsages_ContentFragment";
};
export type AdminContentLibraryItemUsages_ContentFragment$key = {
  readonly " $data"?: AdminContentLibraryItemUsages_ContentFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AdminContentLibraryItemUsages_ContentFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdminContentLibraryItemUsages_ContentFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Content",
  "abstractKey": null
};

(node as any).hash = "2864b38d979dc43456ead9de7118a635";

export default node;
