/**
 * @generated SignedSource<<1bd325490128ca2c8373ed3edd7d9ed8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type usePathwayAdminDropdownItemsFragment$data = {
  readonly id: string;
  readonly slug: string;
  readonly " $fragmentSpreads": FragmentRefs<"DeletePathwayButton_productFragment" | "usePermissionsFragment">;
  readonly " $fragmentType": "usePathwayAdminDropdownItemsFragment";
};
export type usePathwayAdminDropdownItemsFragment$key = {
  readonly " $data"?: usePathwayAdminDropdownItemsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"usePathwayAdminDropdownItemsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "usePathwayAdminDropdownItemsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "usePermissionsFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeletePathwayButton_productFragment"
    }
  ],
  "type": "Product",
  "abstractKey": null
};

(node as any).hash = "1a70da90eeb651efd3ae800898970e10";

export default node;
