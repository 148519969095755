/**
 * @generated SignedSource<<4949f57581e69e1eb58a3864d7ebf8a3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type BadgeKind = "emoji" | "icon" | "upload" | "%future added value";
export type FeedAllowPostsFrom = "admins" | "all" | "groups" | "%future added value";
export type ProductAppStatus = "active" | "inactive" | "%future added value";
export type ProductAppVisibility = "admin" | "all" | "membership" | "%future added value";
export type UpdateProductAppInput = {
  allowPostsFrom?: FeedAllowPostsFrom | null;
  allowPostsGroups?: ReadonlyArray<string> | null;
  allowPostsMembers?: ReadonlyArray<string> | null;
  appDirectoryItems?: ReadonlyArray<AppDirectoryItemInput> | null;
  badge?: BadgeInput | null;
  customAppDescription?: string | null;
  customAppTitle?: string | null;
  customAppUrl?: string | null;
  id: string;
  linkProductId?: string | null;
  navSectionId?: string | null;
  showCompletedUsers?: boolean | null;
  showOnDashboard?: boolean | null;
  status?: ProductAppStatus | null;
  visibility?: ProductAppVisibility | null;
  visibilityGroups?: ReadonlyArray<string> | null;
  visibilityMembers?: ReadonlyArray<string> | null;
};
export type BadgeInput = {
  assetId?: string | null;
  color?: string | null;
  emoji?: string | null;
  icon?: string | null;
  kind: BadgeKind;
  mediaUrl?: string | null;
};
export type AppDirectoryItemInput = {
  memberGroupId: string;
};
export type ToggleAppStatusButtonMutation$variables = {
  input: UpdateProductAppInput;
};
export type ToggleAppStatusButtonMutation$data = {
  readonly response: {
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
    readonly node: {
      readonly id: string;
      readonly status: ProductAppStatus;
    } | null;
  };
};
export type ToggleAppStatusButtonMutation = {
  response: ToggleAppStatusButtonMutation$data;
  variables: ToggleAppStatusButtonMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": "response",
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateProductAppResponse",
    "kind": "LinkedField",
    "name": "updateProductApp",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProductApp",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ToggleAppStatusButtonMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ToggleAppStatusButtonMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c707c8cea42a11a4b85ec00444d6c2ec",
    "id": null,
    "metadata": {},
    "name": "ToggleAppStatusButtonMutation",
    "operationKind": "mutation",
    "text": "mutation ToggleAppStatusButtonMutation(\n  $input: UpdateProductAppInput!\n) {\n  response: updateProductApp(input: $input) {\n    node {\n      id\n      status\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e901377dd78ed3ed01760cae884edb7b";

export default node;
