import PathwayAccessRadioGroup from "@/admin/pathways/settings/PathwayAccessRadioGroup"
import { PathwaySettingsFormStore } from "@/admin/pathways/settings/PathwaySettingsForm"
import ExperienceSettingsMetaDetailsFormFields from "@/product/settings/ExperienceSettingsMetaDetailsFormFields"
import { useExperienceSettingsSelectedPlans_membershipPlansFragment$key } from "@/product/settings/hooks/__generated__/useExperienceSettingsSelectedPlans_membershipPlansFragment.graphql"
import ProductSettingsMembershipPlans from "@/product/settings/ProductSettingsMembershipPlans"
import { observer } from "mobx-react-lite"

type Props = {
  form: PathwaySettingsFormStore
  organizationKey: useExperienceSettingsSelectedPlans_membershipPlansFragment$key
}

function PathwaySettingsAvailability({ form, organizationKey }: Props) {
  return (
    <>
      <PathwayAccessRadioGroup form={form} />
      <ProductSettingsMembershipPlans
        form={form}
        organizationKey={organizationKey}
        productType={"pathway"}
      />
      <ExperienceSettingsMetaDetailsFormFields form={form} productType={"pathway"} />
    </>
  )
}

export default observer(PathwaySettingsAvailability)
