/**
 * @generated SignedSource<<3d9ea9261d5fea91f06d219e14a56c83>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BadgeKind = "emoji" | "icon" | "upload" | "%future added value";
export type LabelKind = "admin_experience" | "admin_member" | "assignment" | "curriculum" | "custom" | "experience" | "lesson" | "organization_admin" | "organization_member" | "organization_owner" | "pathway" | "product_admin" | "product_instructor" | "product_member" | "quiz" | "sidebar_community" | "sidebar_product" | "%future added value";
export type OrganizationCheckoutVersion = "stripe" | "stripe_acacia" | "%future added value";
export type OrganizationCurrency = "aed" | "afn" | "all" | "amd" | "ang" | "aoa" | "ars" | "aud" | "awg" | "azn" | "bam" | "bbd" | "bdt" | "bgn" | "bmd" | "bnd" | "bob" | "brl" | "bsd" | "bwp" | "byn" | "bzd" | "cad" | "chf" | "cny" | "cop" | "crc" | "cve" | "czk" | "dkk" | "dop" | "dzd" | "egp" | "etb" | "eur" | "fjd" | "fkp" | "gbp" | "gel" | "gip" | "gmd" | "hkd" | "hnl" | "htg" | "huf" | "idr" | "ils" | "inr" | "isk" | "jmd" | "kgs" | "lak" | "lbp" | "lkr" | "lrd" | "lsl" | "mad" | "mdl" | "mkd" | "mmk" | "mnt" | "mop" | "mur" | "mvr" | "mxn" | "myr" | "mzn" | "nad" | "ngn" | "nio" | "nok" | "npr" | "nzd" | "pab" | "pen" | "pgk" | "php" | "pkr" | "pln" | "qar" | "ron" | "rsd" | "rub" | "sar" | "sbd" | "scr" | "sek" | "sgd" | "shp" | "srd" | "std" | "szl" | "thb" | "tjs" | "top" | "try" | "ttd" | "twd" | "uah" | "usd" | "uyu" | "uzs" | "zar" | "zmw" | "%future added value";
export type OrganizationVisibility = "private" | "public" | "%future added value";
export type SearchOptOutEntity = "chat_channel" | "chat_message" | "content_usage" | "feed" | "occurrence" | "post" | "product" | "user" | "%future added value";
export type SearchOptOutRole = "admin" | "member" | "owner" | "%future added value";
export type ThemeKind = "custom" | "dark" | "light" | "%future added value";
export type UpdateOrganizationSettingsInput = {
  badge?: BadgeInput | null;
  billingContactEmail?: string | null;
  checkoutVersion?: OrganizationCheckoutVersion | null;
  cover?: string | null;
  coverAssetId?: string | null;
  currency?: OrganizationCurrency | null;
  darkLogo?: string | null;
  darkLogoAssetId?: string | null;
  description?: string | null;
  discoCompanyDomain?: string | null;
  experienceNavOrdering?: ReadonlyArray<string> | null;
  exploreProductTabSortBySetting?: string | null;
  faviconUrl?: string | null;
  id: string;
  isAskAIEnabled?: boolean | null;
  isChannelsEnabled?: boolean | null;
  isDmEnabled?: boolean | null;
  isEventsEnabled?: boolean | null;
  isExploreProductTabHidden?: boolean | null;
  isProductsEnabled?: boolean | null;
  label?: OrganizationSettingsLabelInput | null;
  logo?: string | null;
  logoAssetId?: string | null;
  name?: string | null;
  searchOptOuts?: ReadonlyArray<SearchOptOutInput> | null;
  showGlobalTabTitles?: boolean | null;
  slug?: string | null;
  theme?: OrganizationSettingsThemeInput | null;
  tracking?: UpdateTrackingInput | null;
  visibility?: OrganizationVisibility | null;
};
export type UpdateTrackingInput = {
  facebookPixelId?: string | null;
  googleAnalyticsId?: string | null;
  googleTagManagerId?: string | null;
};
export type BadgeInput = {
  assetId?: string | null;
  color?: string | null;
  emoji?: string | null;
  icon?: string | null;
  kind: BadgeKind;
  mediaUrl?: string | null;
};
export type OrganizationSettingsThemeInput = {
  backgroundColor?: string | null;
  cardBackgroundColor?: string | null;
  kind?: ThemeKind | null;
  navBackgroundColor?: string | null;
  primaryColor?: string | null;
  primaryContrastColor?: string | null;
};
export type OrganizationSettingsLabelInput = {
  labels: ReadonlyArray<UpdateLabelInput>;
};
export type UpdateLabelInput = {
  description?: string | null;
  kind: LabelKind;
  plural: string;
  singular: string;
};
export type SearchOptOutInput = {
  entity: SearchOptOutEntity;
  role: SearchOptOutRole;
};
export type AdminAppearancePageMutation$variables = {
  input: UpdateOrganizationSettingsInput;
};
export type AdminAppearancePageMutation$data = {
  readonly response: {
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
    readonly node: {
      readonly badge: {
        readonly color: string | null;
        readonly emoji: string | null;
        readonly icon: string | null;
        readonly kind: BadgeKind;
        readonly mediaUrl: string | null;
      } | null;
      readonly cover: string | null;
      readonly darkLogo: string | null;
      readonly faviconUrl: string | null;
      readonly id: string;
      readonly logo: string | null;
      readonly name: string;
      readonly " $fragmentSpreads": FragmentRefs<"CustomThemeProviderActiveOrganizationFragment" | "LabelsContextActiveOrganizationFragment">;
    } | null;
  };
};
export type AdminAppearancePageMutation = {
  response: AdminAppearancePageMutation$data;
  variables: AdminAppearancePageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "logo",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "darkLogo",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "faviconUrl",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cover",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "icon",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "color",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "emoji",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mediaUrl",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "ValidationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AdminAppearancePageMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "UpdateOrganizationSettingsMutationResponse",
        "kind": "LinkedField",
        "name": "updateOrganizationSettings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Badge",
                "kind": "LinkedField",
                "name": "badge",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/)
                ],
                "storageKey": null
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CustomThemeProviderActiveOrganizationFragment"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "LabelsContextActiveOrganizationFragment"
              }
            ],
            "storageKey": null
          },
          (v13/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AdminAppearancePageMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "UpdateOrganizationSettingsMutationResponse",
        "kind": "LinkedField",
        "name": "updateOrganizationSettings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Badge",
                "kind": "LinkedField",
                "name": "badge",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Theme",
                "kind": "LinkedField",
                "name": "theme",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "primaryColor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "primaryContrastColor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "backgroundColor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cardBackgroundColor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "navBackgroundColor",
                    "storageKey": null
                  },
                  (v8/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "LabelNodeConnection",
                "kind": "LinkedField",
                "name": "labels",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LabelNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Label",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "singular",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "plural",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "description",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v13/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "852382b5fe6809248410d747cddf845a",
    "id": null,
    "metadata": {},
    "name": "AdminAppearancePageMutation",
    "operationKind": "mutation",
    "text": "mutation AdminAppearancePageMutation(\n  $input: UpdateOrganizationSettingsInput!\n) {\n  response: updateOrganizationSettings(input: $input) {\n    node {\n      id\n      logo\n      darkLogo\n      faviconUrl\n      cover\n      name\n      badge {\n        kind\n        icon\n        color\n        emoji\n        mediaUrl\n        id\n      }\n      ...CustomThemeProviderActiveOrganizationFragment\n      ...LabelsContextActiveOrganizationFragment\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n\nfragment CustomThemeProviderActiveOrganizationFragment on Organization {\n  theme {\n    primaryColor\n    primaryContrastColor\n    backgroundColor\n    cardBackgroundColor\n    navBackgroundColor\n    kind\n    id\n  }\n}\n\nfragment LabelsContextActiveOrganizationFragment on Organization {\n  labels {\n    edges {\n      node {\n        id\n        kind\n        singular\n        plural\n        description\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "904d8603e2e74a0f7b1158f54f9af439";

export default node;
