import { UpdateLabelInput } from "@/admin/appearance/__generated__/AdminAppearancePageMutation.graphql"
import { LabelKind } from "@/core/context/__generated__/LabelsContextActiveOrganizationFragment.graphql"
import { ObservableState } from "@/core/form/store/FormStore"
import {
  DiscoFormControl,
  DiscoInput,
  DiscoInputSkeleton,
  DiscoText,
  DiscoTextSkeleton,
} from "@disco-ui"
import useHasEntitlement from "@utils/hook/useHasEntitlement"
import { TestIDProps } from "@utils/typeUtils"
import { pluralize } from "inflected"
import { observer } from "mobx-react-lite"

interface Props extends TestIDProps {
  label: ObservableState<UpdateLabelInput>
}

function LabelFormField(props: Props) {
  const { label } = props
  const { title, description, disable_customization } = LABEL_CONFIG[label.kind]
  const hasCustomBranding = useHasEntitlement("brand_customization")
  if (disable_customization) return null

  return (
    <DiscoFormControl
      label={<DiscoText variant={"body-md-600"}>{title}</DiscoText>}
      sublabel={
        <DiscoText variant={"body-sm"} color={"text.secondary"}>
          {description}
        </DiscoText>
      }
      marginBottom={1}
      disabled={!hasCustomBranding}
    >
      <DiscoInput
        value={label.singular}
        onChange={(e) => (label.singular = e.target.value)}
        onBlur={onSingularBlur}
        disabled={!hasCustomBranding}
        data-testid={`LabelFormField.${label.kind}`}
        fullWidth
      />
    </DiscoFormControl>
  )

  /** For now hiding the plural input cause we can auto-compute and makes the demo look simpler */
  function onSingularBlur() {
    // remove trailing/leading white space
    label.singular = label.singular.trim()
    label.plural = pluralize(label.singular)
  }
}

export function LabelFormFieldSkeleton({
  height = 48,
  width = 192,
}: {
  height?: number
  width?: number
}) {
  return (
    <div>
      <DiscoTextSkeleton width={150} variant={"body-md-600"} />

      <div style={{ width }}>
        <DiscoInputSkeleton height={height} />
      </div>
    </div>
  )
}

export default observer(LabelFormField)

export const LABEL_CONFIG: Record<
  LabelKind,
  { title: string; description: string; disable_customization?: boolean }
> = {
  organization_owner: {
    title: "Community Owner",
    description: "Owner of your Disco Community. (Default: Owner)",
  },
  organization_admin: {
    title: "Community Admin",
    description: "Admin of your Disco Community. (Default: Admin)",
  },
  organization_member: {
    title: "Community Member",
    description: "User who has joined your Disco Community. (Default: Member)",
  },
  product_admin: {
    title: "Experience Manager",
    description: "Manager of an experience in your Disco Community. (Default: Manager)",
  },
  product_instructor: {
    title: "Experience Instructor",
    description:
      "Instructor of an experience in your Disco Community. (Default: Instructor)",
  },
  product_member: {
    title: "Experience Member",
    description: "User within an experience in your Disco Community. (Default: Member)",
  },
  admin_experience: {
    title: "Admin Product",
    description: "Products you offer in your Disco Community. (Default: Product)",
    disable_customization: true,
  },
  admin_member: {
    title: "Admin Member",
    description: "Members of your product / community, when shown in the admin panel.",
    disable_customization: true,
  },
  experience: {
    title: "Product",
    description: "Products you offer in your Disco Community. (Default: Product)",
  },
  curriculum: {
    title: "Curriculum",
    description:
      "The Curriculum consists of Modules that enable you to organize the course. (Default: Curriculum)",
  },
  custom: {
    title: "Task",
    description:
      "Content within a curriculum that requires the user to take an action and mark the item as complete. (Default: Task)",
  },
  lesson: {
    title: "Lesson",
    description:
      "Content within the curriculum that requires the user to read something or watch a video. (Default: Lesson)",
  },
  assignment: {
    title: "Assignment",
    description:
      "Content within the curriculum that requires the user to make a submission. (Default: Assignment)",
  },
  quiz: {
    title: "Quiz",
    description:
      "Content within the curriculum that requires the user to make a submission. (Default: Quiz)",
  },
  sidebar_community: {
    title: "Home",
    description: "The label for the home area in the sidebar (Default: Home)",
  },
  sidebar_product: {
    title: "Learning",
    description: "The label for the products area in the sidebar (Default: Learning)",
  },
  pathway: {
    title: "Pathway",
    description: "A structured sequence of products. (Default: Pathway)",
  },
  "%future added value": {
    title: "Unknown",
    description: "unknown",
  },
}
