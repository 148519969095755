import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import { DiscoText, DiscoTextSkeleton, DiscoTooltip } from "@disco-ui"
import { DiscoStatisticsTagVariant } from "@disco-ui/tag/statistics/DiscoStatisticsTag"
import { Paper } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { ClassNameMap } from "@material-ui/styles"
import classnames from "classnames"
import { ReactNode } from "react"

type StatisticsReportCardProps = {
  value?: string | number | null | React.ReactNode
  subtitle: string
  tooltipText?: string
  buttons?: ReactNode
  tags?: ReactNode
  testid?: string
  links?: ReactNode
  classes?: Partial<ClassNameMap<"root">>
  tagVariant?: DiscoStatisticsTagVariant
}

function StatisticsReportCard(props: StatisticsReportCardProps) {
  const {
    value,
    tagVariant = "square",
    subtitle,
    tooltipText,
    buttons,
    tags,
    testid = "StatisticsReportCard",
    classes: propClasses,
  } = props

  const classes = useStyles({ tagVariant })

  if (value === undefined || value === null)
    return <StatisticsReportCardError subtitle={subtitle} />
  return (
    <Paper
      elevation={0}
      className={classnames(classes.paper, propClasses?.root)}
      data-testid={testid}
    >
      <div className={classes.headerContainer}>
        <DiscoText
          variant={"heading-xxl"}
          className={classes.value}
          testid={`${testid}.value`}
        >
          {
            /** If value is already formatted, display as-is */
            typeof value === "string"
              ? value
              : typeof value ===
                "number" /** Display large number in compact format. ie, 1.5k, 2.2M */
              ? new Intl.NumberFormat("en", { notation: "compact" }).format(Number(value))
              : value || "--"
          }
        </DiscoText>
        {tagVariant === "round" && tags && (
          <div className={classes.tags} data-testid={`${testid}.tags`}>
            {tags}
          </div>
        )}

        {buttons && (
          <div data-testid={`${testid}.buttons`} className={classes.buttons}>
            {buttons}
          </div>
        )}
      </div>
      <div className={classes.subtitleContainer}>
        <DiscoText
          noWrap
          testid={`${testid}.subtitle`}
          color={"text.secondary"}
          variant={"body-sm"}
        >
          {subtitle}
        </DiscoText>
        {tooltipText && (
          <DiscoTooltip
            content={tooltipText}
            buttonProps={{ classes: { root: classes.iconButton } }}
          />
        )}
      </div>
      <div className={classes.bottomContainer}>
        {tagVariant === "square" && tags && (
          <div className={classes.tags} data-testid={`${testid}.tags`}>
            {tags}
          </div>
        )}
      </div>
    </Paper>
  )
}

type StatisticsReportCardErrorProps = {
  subtitle: string
}

function StatisticsReportCardError(props: StatisticsReportCardErrorProps) {
  const classes = useStyles({ tagVariant: "square" })
  return (
    <Paper
      elevation={0}
      className={classes.paper}
      data-testid={"StatisticsReportCard.error"}
    >
      <DiscoText
        color={"error.main"}
      >{`Failed to retrieve information for: ${props.subtitle}`}</DiscoText>
    </Paper>
  )
}

type StyleProps = {
  tagVariant?: DiscoStatisticsTagVariant
}

const useStyles = makeUseStyles((theme) => ({
  paper: {
    minWidth: 0,
    padding: theme.spacing(2),
    borderRadius: theme.measure.borderRadius.big,
    height: "100%",
  },
  headerContainer: ({ tagVariant }: StyleProps) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: tagVariant === "round" ? "flex-start" : "space-between",
    marginBottom: theme.spacing(0.5),
    ...styleIf(tagVariant === "round", {
      gap: theme.spacing(1.5),
    }),
  }),
  value: {
    fontWeight: 300,
  },
  subtitleContainer: {
    display: "flex",
    alignItems: "center",
  },
  iconButton: {
    marginLeft: theme.spacing(0.5),
    width: "16px",
  },
  bottomContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  buttons: {
    alignSelf: "flex-start",
  },
  tags: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: theme.spacing(1),
  },
}))

export default StatisticsReportCard

export function StatisticsReportCardSkeleton(props: Partial<StatisticsReportCardProps>) {
  const { classes: customClasses, tagVariant = "square" } = props
  const classes = useSkeletonStyles({ tagVariant })

  return (
    <Paper elevation={0} className={classnames(classes.paper, customClasses?.root)}>
      <DiscoText className={classes.value} variant={"heading-xxl"}>
        {"--"}
      </DiscoText>
      <DiscoTextSkeleton width={"100px"} variant={"heading-md"} />
      {tagVariant === "square" && <Skeleton height={"40px"} width={"80px"} />}
    </Paper>
  )
}

const useSkeletonStyles = useStyles
