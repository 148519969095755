/**
 * @generated SignedSource<<a5ab209bf64ba5e374028d27c4eb0eb5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type AutomationBranchFilterOperator = "AND" | "OR" | "%future added value";
export type AutomationFilterLevel = "branch" | "step" | "trigger" | "%future added value";
export type AutomationKind = "custom" | "default" | "system" | "%future added value";
export type AutomationStatus = "draft" | "published" | "%future added value";
export type AutomationStepActionKind = "add_to_group" | "add_to_pathway" | "add_to_product" | "add_to_slack_channel" | "release_certificate" | "remove_from_group" | "remove_from_pathway" | "remove_from_product" | "remove_from_slack_channel" | "send_chat_channel_message" | "send_chat_direct_message" | "send_email" | "send_slack_direct_message" | "%future added value";
export type AutomationStepKind = "action" | "branch" | "delay" | "%future added value";
export type AutomationStepWarningKind = "slack_channel_removed" | "%future added value";
export type AutomationTriggerKind = "member_completed_pathway" | "member_completed_product" | "member_event_rsvp" | "member_joined_community" | "member_joined_course" | "member_joined_event" | "member_joined_group" | "member_joined_pathway" | "member_leaving_community" | "member_left_course" | "member_left_pathway" | "member_onboarding_completed" | "member_removed_group" | "module_completed" | "product_ended" | "profile_field_changed" | "scheduled" | "%future added value";
export type MessageTemplateKind = "community_welcome" | "course_welcome" | "custom" | "product_completion" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type automationUtils_UpdateAutomationFragment$data = {
  readonly id: string;
  readonly kind: AutomationKind;
  readonly name: string;
  readonly status: AutomationStatus;
  readonly steps: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly actionChannels: ReadonlyArray<string> | null;
        readonly actionKind: AutomationStepActionKind | null;
        readonly actionMemberGroupIds: ReadonlyArray<string> | null;
        readonly actionMessageTemplate: {
          readonly chatContent: {
            readonly richBody: string;
            readonly send: boolean;
            readonly sendAs: string | null;
          } | null;
          readonly emailContent: {
            readonly bccEmails: ReadonlyArray<string> | null;
            readonly bccUserIds: ReadonlyArray<string> | null;
            readonly ccEmails: ReadonlyArray<string> | null;
            readonly ccUserIds: ReadonlyArray<string> | null;
            readonly richBody: string;
            readonly send: boolean;
            readonly subject: string;
          } | null;
          readonly id: string;
          readonly kind: MessageTemplateKind | null;
          readonly name: string;
          readonly slackContent: {
            readonly richBody: string;
            readonly send: boolean;
            readonly sendAs: string | null;
          } | null;
        } | null;
        readonly actionProductIds: ReadonlyArray<string> | null;
        readonly branches: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly color: string;
              readonly filterOperator: AutomationBranchFilterOperator;
              readonly filters: {
                readonly edges: ReadonlyArray<{
                  readonly node: {
                    readonly condition: string;
                    readonly id: string;
                    readonly level: AutomationFilterLevel;
                  };
                }>;
              };
              readonly id: string;
              readonly name: string;
              readonly steps: {
                readonly edges: ReadonlyArray<{
                  readonly node: {
                    readonly actionChannels: ReadonlyArray<string> | null;
                    readonly actionKind: AutomationStepActionKind | null;
                    readonly actionMemberGroupIds: ReadonlyArray<string> | null;
                    readonly actionMessageTemplate: {
                      readonly chatContent: {
                        readonly richBody: string;
                        readonly send: boolean;
                        readonly sendAs: string | null;
                      } | null;
                      readonly emailContent: {
                        readonly bccEmails: ReadonlyArray<string> | null;
                        readonly bccUserIds: ReadonlyArray<string> | null;
                        readonly ccEmails: ReadonlyArray<string> | null;
                        readonly ccUserIds: ReadonlyArray<string> | null;
                        readonly richBody: string;
                        readonly send: boolean;
                        readonly subject: string;
                      } | null;
                      readonly id: string;
                      readonly kind: MessageTemplateKind | null;
                      readonly name: string;
                      readonly slackContent: {
                        readonly richBody: string;
                        readonly send: boolean;
                        readonly sendAs: string | null;
                      } | null;
                    } | null;
                    readonly actionProductIds: ReadonlyArray<string> | null;
                    readonly delay: string | null;
                    readonly filters: {
                      readonly edges: ReadonlyArray<{
                        readonly node: {
                          readonly condition: string;
                          readonly id: string;
                          readonly level: AutomationFilterLevel;
                        };
                      }>;
                    } | null;
                    readonly id: string;
                    readonly kind: AutomationStepKind;
                    readonly warningKind: AutomationStepWarningKind | null;
                  };
                }>;
              };
            };
          }>;
        };
        readonly delay: string | null;
        readonly filters: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly condition: string;
              readonly id: string;
              readonly level: AutomationFilterLevel;
            };
          }>;
        } | null;
        readonly id: string;
        readonly kind: AutomationStepKind;
        readonly warningKind: AutomationStepWarningKind | null;
      };
    }>;
    readonly totalCount: number;
  } | null;
  readonly triggers: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly kind: AutomationTriggerKind;
        readonly metadata: {
          readonly memberGroupIds: ReadonlyArray<string> | null;
          readonly moduleIds: ReadonlyArray<string> | null;
          readonly occurrenceIds: ReadonlyArray<string> | null;
          readonly productIds: ReadonlyArray<string> | null;
          readonly profileField: {
            readonly id: string;
            readonly optionIds: ReadonlyArray<string> | null;
            readonly value: string | null;
          } | null;
        } | null;
        readonly schedule: {
          readonly id: string;
          readonly triggerAt: string | null;
        } | null;
      };
    }>;
  };
  readonly " $fragmentSpreads": FragmentRefs<"AutomationsReportsTableRow_Automation">;
  readonly " $fragmentType": "automationUtils_UpdateAutomationFragment";
};
export type automationUtils_UpdateAutomationFragment$key = {
  readonly " $data"?: automationUtils_UpdateAutomationFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"automationUtils_UpdateAutomationFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actionKind",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "delay",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actionMemberGroupIds",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actionChannels",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actionProductIds",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "warningKind",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "AutomationFilterNodeConnection",
  "kind": "LinkedField",
  "name": "filters",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AutomationFilterNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AutomationFilter",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "level",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "condition",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "send",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "richBody",
  "storageKey": null
},
v12 = [
  (v10/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "sendAs",
    "storageKey": null
  },
  (v11/*: any*/)
],
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "MessageTemplate",
  "kind": "LinkedField",
  "name": "actionMessageTemplate",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "MessageTemplateEmailContent",
      "kind": "LinkedField",
      "name": "emailContent",
      "plural": false,
      "selections": [
        (v10/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "subject",
          "storageKey": null
        },
        (v11/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ccEmails",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bccEmails",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ccUserIds",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bccUserIds",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MessageTemplateSlackContent",
      "kind": "LinkedField",
      "name": "slackContent",
      "plural": false,
      "selections": (v12/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MessageTemplateChatContent",
      "kind": "LinkedField",
      "name": "chatContent",
      "plural": false,
      "selections": (v12/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "automationUtils_UpdateAutomationFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AutomationsReportsTableRow_Automation"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AutomationTriggerNodeConnection",
      "kind": "LinkedField",
      "name": "triggers",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AutomationTriggerNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AutomationTrigger",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AutomationTriggerMetadata",
                  "kind": "LinkedField",
                  "name": "metadata",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "moduleIds",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "productIds",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AutomationTriggerProfileFieldMetadata",
                      "kind": "LinkedField",
                      "name": "profileField",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "value",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "optionIds",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "occurrenceIds",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "memberGroupIds",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": "schedule",
                  "args": null,
                  "concreteType": "AutomationTriggerSchedule",
                  "kind": "LinkedField",
                  "name": "automationTriggerSchedule",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "triggerAt",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AutomationStepNodeConnection",
      "kind": "LinkedField",
      "name": "steps",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AutomationStepNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AutomationStep",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/),
                (v4/*: any*/),
                (v5/*: any*/),
                (v6/*: any*/),
                (v7/*: any*/),
                (v8/*: any*/),
                (v9/*: any*/),
                (v13/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AutomationBranchNodeConnection",
                  "kind": "LinkedField",
                  "name": "branches",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AutomationBranchNodeEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "AutomationBranch",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v1/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "color",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "filterOperator",
                              "storageKey": null
                            },
                            (v9/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "AutomationStepNodeConnection",
                              "kind": "LinkedField",
                              "name": "steps",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "AutomationStepNodeEdge",
                                  "kind": "LinkedField",
                                  "name": "edges",
                                  "plural": true,
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "AutomationStep",
                                      "kind": "LinkedField",
                                      "name": "node",
                                      "plural": false,
                                      "selections": [
                                        (v0/*: any*/),
                                        (v2/*: any*/),
                                        (v3/*: any*/),
                                        (v4/*: any*/),
                                        (v5/*: any*/),
                                        (v6/*: any*/),
                                        (v7/*: any*/),
                                        (v8/*: any*/),
                                        (v9/*: any*/),
                                        (v13/*: any*/)
                                      ],
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Automation",
  "abstractKey": null
};
})();

(node as any).hash = "b7c35ba2486c4243040eaa72544a1733";

export default node;
