import { ProductListSortDropdownMutation } from "@/admin/community/product-list/__generated__/ProductListSortDropdownMutation.graphql"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useFormStore } from "@/core/form/store/FormStore"
import { ProductOrderBy } from "@/product/card/grid/__generated__/ProductCardGridPaginationQuery.graphql"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoDropdown, DiscoIcon } from "@disco-ui"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import DiscoWarningModal from "@disco-ui/modal/DiscoWarningModal"
import useDisclosure from "@utils/hook/useDisclosure"
import { TestIDProps } from "@utils/typeUtils"
import { useState } from "react"
import { graphql } from "relay-runtime"

interface ProductListSortDropdownProps extends TestIDProps {
  testid: string
}

export type ProductListSortOption = {
  value: ProductOrderBy
  title: string
}

export const PRODUCT_LIST_SORT_OPTIONS: ProductListSortOption[] = [
  {
    value: "nav",
    title: "Order in Left Nav",
  },
  {
    value: "name",
    title: "Alphabetical",
  },
  {
    value: "published",
    title: "Date published",
  },
]

function ProductListSortDropdown({ testid }: ProductListSortDropdownProps) {
  const classes = useStyles()
  const { isOpen, onClose, onOpen } = useDisclosure()
  const [selectedOption, setSelectedOption] = useState<ProductListSortOption>({
    value: "nav",
    title: "Order in Nav",
  })

  const activeOrganization = useActiveOrganization()!
  const form = useFormStore<ProductListSortDropdownMutation>(
    graphql`
      mutation ProductListSortDropdownMutation($input: UpdateOrganizationSettingsInput!) {
        response: updateOrganizationSettings(input: $input) {
          node {
            id
            exploreProductTabSortBySetting
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      id: activeOrganization.id,
      exploreProductTabSortBySetting: activeOrganization.exploreProductTabSortBySetting,
    }
  )

  return (
    <>
      <DiscoDropdown
        testid={`${testid}.dropdown`}
        menuButton={({ onClick }) => (
          <DiscoDropdownItem
            testid={`${testid}.button`}
            title={"Sort by"}
            icon={<DiscoIcon icon={"sort"} />}
            rightIcon={<DiscoIcon icon={"chevron"} rotate={"90"} />}
            onClick={onClick}
          />
        )}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        isNested
      >
        {PRODUCT_LIST_SORT_OPTIONS.map((option) => (
          <DiscoDropdownItem
            key={`${testid}.dropdown.option`}
            testid={`${testid}.dropdown.option.${option.value}`}
            title={option.title}
            selected={option.value === activeOrganization.exploreProductTabSortBySetting}
            onClick={() => {
              onOpen()
              setSelectedOption(option)
            }}
            rightIcon={
              option.value === activeOrganization.exploreProductTabSortBySetting ? (
                <span className={classes.checkedIcon}>
                  <DiscoIcon icon={"check"} />
                </span>
              ) : undefined
            }
          />
        ))}
      </DiscoDropdown>

      {isOpen && (
        <DiscoWarningModal
          testid={`${testid}.save-modal`}
          isOpen={isOpen}
          onClose={onClose}
          title={"Confirm Changes"}
          description={
            "Updating the sort by order will update this view for everyone, including both admins and members."
          }
          variant={"primary"}
          hideIcon
          confirmationButtonProps={{
            onClick: () => handleSubmit(selectedOption),
            shouldDisplaySpinner: form.isSubmitting,
            children: "Confirm",
          }}
          modalContentLabel={""}
        />
      )}
    </>
  )

  async function handleSubmit(option: ProductListSortOption) {
    // PRO-65: what's the pattern we have for error handling here?
    const { didSave } = await form.submit({
      id: activeOrganization.id,
      exploreProductTabSortBySetting: option.value,
    })
    if (!didSave) return
    onClose()
  }
}

const useStyles = makeUseStyles((theme) => ({
  checkedIcon: {
    "& svg": {
      color: `${theme.palette.primary.main} !important`,
    },
  },
}))

export default ProductListSortDropdown
