/**
 * @generated SignedSource<<d725f52eecdb495df22ad6b1b5aa93e2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AdminContentListCollectionAppFilterFragment$data = {
  readonly apps: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly collection: {
          readonly id: string;
        } | null;
        readonly customAppTitle: string | null;
        readonly id: string;
      };
    }>;
  };
  readonly id: string;
  readonly " $fragmentType": "AdminContentListCollectionAppFilterFragment";
};
export type AdminContentListCollectionAppFilterFragment$key = {
  readonly " $data"?: AdminContentListCollectionAppFilterFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AdminContentListCollectionAppFilterFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdminContentListCollectionAppFilterFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "includeNested",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "kind",
          "value": "collection"
        }
      ],
      "concreteType": "ProductAppNodeConnection",
      "kind": "LinkedField",
      "name": "apps",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductAppNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProductApp",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "customAppTitle",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Collection",
                  "kind": "LinkedField",
                  "name": "collection",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "apps(includeNested:true,kind:\"collection\")"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "cede6b20dcb7d05338804888c22cd19e";

export default node;
