/**
 * @generated SignedSource<<f1dc62a23f732423dfb4b945fe012807>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ProfileFieldStatus = "hidden" | "visible" | "%future added value";
export type WebFormQuestionType = "link" | "multiple_select" | "ranking" | "rating" | "single_select" | "written_answer" | "%future added value";
export type UpdateProfileFieldsInput = {
  organizationId: string;
  profileFields: ReadonlyArray<ProfileFieldInput>;
  webFormRevision?: CreateWebFormRevisionInput | null;
};
export type ProfileFieldInput = {
  id?: string | null;
  isPrivate: boolean;
  status: ProfileFieldStatus;
  title: string;
  webFormQuestionIndex?: number | null;
};
export type CreateWebFormRevisionInput = {
  contentUsageId?: string | null;
  questions: ReadonlyArray<WebFormQuestionInput>;
  webFormId: string;
};
export type WebFormQuestionInput = {
  answerKey?: WebFormQuestionAnswerKeyInput | null;
  isRequired?: boolean | null;
  options?: ReadonlyArray<WebFormQuestionOptionInput> | null;
  richEditorBody: string;
  type: WebFormQuestionType;
};
export type WebFormQuestionOptionInput = {
  id: string;
  label: string;
};
export type WebFormQuestionAnswerKeyInput = {
  correctIds: ReadonlyArray<string>;
  feedback?: string | null;
};
export type AdminProfileFieldsPageMutation$variables = {
  input: UpdateProfileFieldsInput;
};
export type AdminProfileFieldsPageMutation$data = {
  readonly response: {
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
    readonly node: {
      readonly id: string;
      readonly profileFields: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly isDefault: boolean;
            readonly isPrivate: boolean;
            readonly status: ProfileFieldStatus;
            readonly title: string;
            readonly webFormQuestionId: string | null;
          };
        }>;
      };
      readonly profileWebForm: {
        readonly currentRevision: {
          readonly questions: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly answerKey: {
                  readonly correctIds: ReadonlyArray<string>;
                  readonly feedback: string | null;
                } | null;
                readonly id: string;
                readonly isRequired: boolean;
                readonly options: ReadonlyArray<{
                  readonly id: string;
                  readonly label: string;
                }> | null;
                readonly ordering: number;
                readonly richEditorBody: string;
                readonly type: WebFormQuestionType;
              };
            }>;
          };
          readonly webFormId: string;
        };
        readonly id: string;
        readonly submissions: {
          readonly totalCount: number;
        };
      } | null;
    } | null;
  };
};
export type AdminProfileFieldsPageMutation = {
  response: AdminProfileFieldsPageMutation$data;
  variables: AdminProfileFieldsPageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "ProfileFieldNodeConnection",
  "kind": "LinkedField",
  "name": "profileFields",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProfileFieldNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProfileField",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isDefault",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isPrivate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "webFormQuestionId",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "webFormId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "WebFormQuestionNodeConnection",
  "kind": "LinkedField",
  "name": "questions",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "WebFormQuestionNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "WebFormQuestion",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "ordering",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "richEditorBody",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isRequired",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "WebFormQuestionOption",
              "kind": "LinkedField",
              "name": "options",
              "plural": true,
              "selections": [
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "label",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "WebFormQuestionAnswerKey",
              "kind": "LinkedField",
              "name": "answerKey",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "correctIds",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "feedback",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "WebFormSubmissionNodeConnection",
  "kind": "LinkedField",
  "name": "submissions",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "ValidationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AdminProfileFieldsPageMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "UpdateProfileFieldsResponse",
        "kind": "LinkedField",
        "name": "updateProfileFields",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "WebForm",
                "kind": "LinkedField",
                "name": "profileWebForm",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WebFormRevision",
                    "kind": "LinkedField",
                    "name": "currentRevision",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AdminProfileFieldsPageMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "UpdateProfileFieldsResponse",
        "kind": "LinkedField",
        "name": "updateProfileFields",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "WebForm",
                "kind": "LinkedField",
                "name": "profileWebForm",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WebFormRevision",
                    "kind": "LinkedField",
                    "name": "currentRevision",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ca31ee145b18d0f2c792a487815dfe27",
    "id": null,
    "metadata": {},
    "name": "AdminProfileFieldsPageMutation",
    "operationKind": "mutation",
    "text": "mutation AdminProfileFieldsPageMutation(\n  $input: UpdateProfileFieldsInput!\n) {\n  response: updateProfileFields(input: $input) {\n    node {\n      id\n      profileFields {\n        edges {\n          node {\n            id\n            title\n            isDefault\n            status\n            isPrivate\n            webFormQuestionId\n          }\n        }\n      }\n      profileWebForm {\n        id\n        currentRevision {\n          webFormId\n          questions {\n            edges {\n              node {\n                id\n                type\n                ordering\n                richEditorBody\n                isRequired\n                options {\n                  id\n                  label\n                }\n                answerKey {\n                  correctIds\n                  feedback\n                }\n              }\n            }\n          }\n          id\n        }\n        submissions {\n          totalCount\n        }\n      }\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "61f1efcecef45b9dda343522d51d28d1";

export default node;
