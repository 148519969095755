import useCopilotBot from "@/admin/integrations/copilot/util/useCopilotBot"
import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import UnsavedChangesModalProvider, {
  useInitUnsavedChangesModalContext,
} from "@/core/context/UnsavedChangesModalProvider"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoDrawer, DiscoDrawerHeader } from "@disco-ui"
import lazyWithRetry from "@utils/lazy/lazyWithRetry"

const CopilotBotSettingsForm = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "copilot-bot-settings-form" */ "@/admin/integrations/copilot/CopilotBotSettingsForm"
    )
)

function CopilotBotSettingsDrawer() {
  const classes = useStyles()
  const drawer = useGlobalDrawer("copilotSettings")
  const modal = useInitUnsavedChangesModalContext()
  const copilotBot = useCopilotBot()
  const copilotBotName = copilotBot?.name || "Q&A Suggestions"

  return (
    // If leaving with unsaved changes, display the unsaved changes modal
    <UnsavedChangesModalProvider {...modal}>
      <DiscoDrawer
        open={drawer.isOpen}
        onClose={handleClose}
        size={"large"}
        containerClasses={{
          drawerContainer: classes.drawer,
        }}
      >
        <DiscoDrawerHeader
          overlineTitle={"Configure"}
          title={copilotBotName}
          onClose={handleClose}
          className={classes.drawerHeader}
          disableExpand
        />

        {drawer.isOpen && <CopilotBotSettingsForm />}
      </DiscoDrawer>
    </UnsavedChangesModalProvider>
  )

  function handleClose() {
    drawer.close()
  }
}

const useStyles = makeUseStyles((theme) => ({
  drawerHeader: {
    padding: theme.spacing(3, 3, 0),
  },
  drawer: {
    padding: 0,
  },
}))

export default CopilotBotSettingsDrawer
