/**
 * @generated SignedSource<<9cc18b387c23e741e247756c5eccf008>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ProductType = "community_event" | "course" | "membership_plan" | "pathway" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DeletePathwayButton_productFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly type: ProductType;
  readonly " $fragmentSpreads": FragmentRefs<"usePermissionsFragment">;
  readonly " $fragmentType": "DeletePathwayButton_productFragment";
};
export type DeletePathwayButton_productFragment$key = {
  readonly " $data"?: DeletePathwayButton_productFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeletePathwayButton_productFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeletePathwayButton_productFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "usePermissionsFragment"
    }
  ],
  "type": "Product",
  "abstractKey": null
};

(node as any).hash = "5bd8e0cd58cbead7192f4fb4d9f2c143";

export default node;
