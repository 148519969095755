/**
 * @generated SignedSource<<61ae37ba4629b2cb5ac8eb1fc20807ea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ZoomConnectionHumanReadableUserPlanType = "basic" | "licensed" | "none" | "premium" | "unknown" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type OrganizationZoomConnectionListItem_meetingProviderFragment$data = {
  readonly id: string;
  readonly organization: {
    readonly hasZoomIntegration: boolean;
    readonly id: string;
  };
  readonly user: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"ProfileAvatarWithDetailsFragment">;
  };
  readonly zoomConnection: {
    readonly email: string | null;
    readonly firstName: string | null;
    readonly hasWebinarAddon: boolean | null;
    readonly id: string;
    readonly isConnected: boolean;
    readonly lastName: string | null;
    readonly meetingCapacity: number | null;
    readonly picUrl: string | null;
    readonly userPlanType: ZoomConnectionHumanReadableUserPlanType;
    readonly webinarCapacity: number | null;
  };
  readonly " $fragmentType": "OrganizationZoomConnectionListItem_meetingProviderFragment";
};
export type OrganizationZoomConnectionListItem_meetingProviderFragment$key = {
  readonly " $data"?: OrganizationZoomConnectionListItem_meetingProviderFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrganizationZoomConnectionListItem_meetingProviderFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrganizationZoomConnectionListItem_meetingProviderFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ZoomConnection",
      "kind": "LinkedField",
      "name": "zoomConnection",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "picUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "userPlanType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasWebinarAddon",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "meetingCapacity",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "webinarCapacity",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isConnected",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasZoomIntegration",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProfileAvatarWithDetailsFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "MeetingProvider",
  "abstractKey": null
};
})();

(node as any).hash = "5c7357de312d75ddc3177acb962e7374";

export default node;
