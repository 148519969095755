/**
 * @generated SignedSource<<d13159cab40b32ef71eb1fce987ad45a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type BadgeKind = "emoji" | "icon" | "upload" | "%future added value";
export type PricingFrequency = "annually" | "monthly" | "weekly" | "%future added value";
export type PricingKind = "free" | "one_time" | "recurring" | "%future added value";
export type ProductRegistrationAvailability = "hidden" | "private" | "public" | "%future added value";
export type ProductStatus = "archived" | "draft" | "published" | "unlisted" | "%future added value";
export type UpdatePathwayInput = {
  badge?: BadgeInput | null;
  cover?: string | null;
  coverAssetId?: string | null;
  description?: string | null;
  landingPage?: ProductLandingPageInput | null;
  membershipBenefits?: ReadonlyArray<UpdateMembershipBenefitInput> | null;
  name?: string | null;
  pathwayGroups?: ReadonlyArray<UpdatePathwayGroupInput> | null;
  pathwayId?: string | null;
  productId?: string | null;
  registrationAvailability?: ProductRegistrationAvailability | null;
  richEditorCheckoutDescription?: string | null;
  slug?: string | null;
  status?: ProductStatus | null;
};
export type BadgeInput = {
  assetId?: string | null;
  color?: string | null;
  emoji?: string | null;
  icon?: string | null;
  kind: BadgeKind;
  mediaUrl?: string | null;
};
export type ProductLandingPageInput = {
  metaDescription?: string | null;
  metaImageAssetId?: string | null;
  metaImageUrl?: string | null;
  metaTitle?: string | null;
  mode?: string | null;
};
export type UpdatePathwayGroupInput = {
  id?: string | null;
  productIds: ReadonlyArray<string>;
  title: string;
};
export type UpdateMembershipBenefitInput = {
  id?: string | null;
  membershipPlanId: string;
  pricing: UpdatePricingInput;
};
export type UpdatePricingInput = {
  amountCents: number;
  frequency?: PricingFrequency | null;
  kind: PricingKind;
};
export type PublishPathwayButtonMutation$variables = {
  input: UpdatePathwayInput;
};
export type PublishPathwayButtonMutation$data = {
  readonly response: {
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
    readonly node: {
      readonly id: string;
      readonly status: ProductStatus;
    } | null;
  };
};
export type PublishPathwayButtonMutation = {
  response: PublishPathwayButtonMutation$data;
  variables: PublishPathwayButtonMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": "response",
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdatePathwayResponse",
    "kind": "LinkedField",
    "name": "updatePathway",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Product",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PublishPathwayButtonMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PublishPathwayButtonMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c91641e0e022ae8a47cbfeafe5af41fb",
    "id": null,
    "metadata": {},
    "name": "PublishPathwayButtonMutation",
    "operationKind": "mutation",
    "text": "mutation PublishPathwayButtonMutation(\n  $input: UpdatePathwayInput!\n) {\n  response: updatePathway(input: $input) {\n    node {\n      id\n      status\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "95d1ee49fc52a01199fc54e66f5df4f0";

export default node;
