import usePaginatedProductList from "@/admin/community/product-list/hooks/usePaginatedProductList"
import CreatePathwayForm from "@/admin/pathways/CreatePathwayForm"
import CreatePathwayGroupsForm from "@/admin/pathways/CreatePathwayGroupsForm"
import useCreatePathwayForm from "@/admin/pathways/hooks/useCreatePathwayForm"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import { useLabel } from "@/core/context/LabelsContext"
import { useAdminProductLabel } from "@/product/util/hook/useProductLabel"
import ProductUtils from "@/product/util/productUtils"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoAlert, DiscoButton, DiscoModal } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { useState } from "react"
import ConnectionHandler from "relay-connection-handler-plus"

interface CreatePathwayModalProps extends TestIDProps {
  onClose: () => void
}

function CreatePathwayModal({
  testid = "CreatePathwayModal",
  onClose,
}: CreatePathwayModalProps) {
  const [step, setStep] = useState<0 | 1>(0)
  const form = useCreatePathwayForm()
  const adminCourseLabel = useAdminProductLabel("course")
  const adminPathwayLabel = useAdminProductLabel("pathway")
  const invalidateSidebar = ProductUtils.useInvalidateSidebar()
  const memberLabel = useLabel("organization_member")
  const registrationDrawer = useGlobalDrawer("registration")
  const activeOrganization = useActiveOrganization()!

  if (step === 0)
    return (
      <DiscoModal
        testid={`${testid}.modal`}
        isOpen
        onClose={onClose}
        title={`Create ${adminPathwayLabel.singular}`}
        subtitle={`${adminPathwayLabel.plural} allow you to group multiple ${adminCourseLabel.plural} into a sequential program to achieve specific learning goals. Easily chart ${memberLabel.singular} progress and guide them through each ${adminCourseLabel.singular} in a simple, structured interface.`}
        modalContentLabel={`Create ${adminPathwayLabel.singular}`}
        width={"720px"}
        maxWidth={"90vw"}
        disableTransition
        body={<CreatePathwayForm form={form} />}
        buttons={[
          <DiscoButton
            key={"cancel"}
            color={"grey"}
            variant={"outlined"}
            onClick={onClose}
            testid={`${testid}.cancel.button`}
          >
            {"Cancel"}
          </DiscoButton>,
          <DiscoButton
            key={"next-step"}
            testid={`${testid}.next.button`}
            onClick={handleNextStep}
          >
            {"Next"}
          </DiscoButton>,
        ]}
      />
    )

  return (
    <DiscoModal
      testid={`${testid}.modal`}
      isOpen
      onClose={onClose}
      title={`${adminPathwayLabel.singular} Sequence`}
      modalContentLabel={`${adminPathwayLabel.singular} Sequence`}
      subtitle={`Add ${adminCourseLabel.plural} into a sequence of ${adminPathwayLabel.singular} Groups. ${memberLabel.plural} can only proceed to the next ${adminPathwayLabel.singular} Group once they have completed all items in the previous ${adminPathwayLabel.singular} Group.`}
      width={"720px"}
      maxWidth={"90vw"}
      disableTransition
      body={
        <>
          <CreatePathwayGroupsForm form={form} onSubmit={handleSubmit} />
          {/* TODO: add learn more link when pathways KB written */}
          <DiscoAlert
            message={`${memberLabel.plural} joining a ${adminPathwayLabel.singular} will bypass any ${adminCourseLabel.singular} registration requirements, including applications, capacity, and pricing.`}
            marginTop={3}
          />
        </>
      }
      buttons={[
        <DiscoButton
          key={"back"}
          color={"grey"}
          variant={"outlined"}
          onClick={() => setStep(0)}
          testid={`${testid}.back.button`}
        >
          {"Back"}
        </DiscoButton>,
        <DiscoButton
          key={"create-pathway"}
          type={"submit"}
          form={"CreatePathwayGroupsForm"}
          testid={`${testid}.submit.button`}
          shouldDisplaySpinner={form.isSubmitting}
          disabled={form.isSubmitting}
        >
          {"Save as Draft"}
        </DiscoButton>,
      ]}
    />
  )

  function handleNextStep() {
    if (form.state.name === "")
      return form.addError({ field: "name", message: "Title is required." })
    if ((form.state.description?.length ?? 0) > 255)
      return form.addError({
        field: "description",
        message: "Description must be less than 255 characters.",
      })
    setStep(1)
  }

  async function handleSubmit() {
    const { productCache: _, ...input } = form.state
    const { response, didSave } = await form.submit(input, {
      updater(store, data) {
        if (data.response.errors?.length) return
        const orgRecord = store.get(activeOrganization.id)
        if (!orgRecord) return
        ConnectionHandler.getConnections(
          orgRecord,
          "AdminPathwaysListTable_pathwayProducts"
        ).forEach((conn) => conn.invalidateRecord())

        invalidateSidebar(store)
      },
    })
    if (!didSave || !response?.node) return
    displaySuccessToast({
      message: "Pathway created 🎉",
      testid: `${testid}.success-toast`,
    })
    usePaginatedProductList.invalidatePathwaysConnections(activeOrganization.id)
    onClose()
    registrationDrawer.open({
      drawerRegistrationExperienceId: response.node.id,
    })
  }
}

export default observer(CreatePathwayModal)
