/**
 * @generated SignedSource<<738a8f57f9e324e40eb2a616d46052d7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommunityDirectMessagesPageContent_PaginationFragment$data = {
  readonly chatChannels: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly chatChannelMembers: {
          readonly totalCount: number;
        };
        readonly externalChannelId: string;
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"DirectMessagesListItemFragment">;
      };
    }>;
    readonly pageInfo: {
      readonly endCursor: string | null;
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
      readonly startCursor: string | null;
    };
  };
  readonly id: string;
  readonly " $fragmentType": "CommunityDirectMessagesPageContent_PaginationFragment";
};
export type CommunityDirectMessagesPageContent_PaginationFragment$key = {
  readonly " $data"?: CommunityDirectMessagesPageContent_PaginationFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommunityDirectMessagesPageContent_PaginationFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "chatChannels"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./CommunityDirectMessagesPageContentPaginationQuery.graphql'),
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "CommunityDirectMessagesPageContent_PaginationFragment",
  "selections": [
    {
      "alias": "chatChannels",
      "args": [
        {
          "kind": "Literal",
          "name": "kind",
          "value": "direct_message"
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": {
            "direction": "DESC",
            "field": "last_message_sent_at"
          }
        }
      ],
      "concreteType": "ChatChannelNodeConnection",
      "kind": "LinkedField",
      "name": "__CommunityDirectMessagesPageContent__chatChannels_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ChatChannelNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ChatChannel",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "externalChannelId",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "DirectMessagesListItemFragment"
                },
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "excludeViewer",
                      "value": true
                    }
                  ],
                  "concreteType": "ChatChannelMemberNodeConnection",
                  "kind": "LinkedField",
                  "name": "chatChannelMembers",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "totalCount",
                      "storageKey": null
                    }
                  ],
                  "storageKey": "chatChannelMembers(excludeViewer:true)"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfoObjectType",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasPreviousPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "__CommunityDirectMessagesPageContent__chatChannels_connection(kind:\"direct_message\",orderBy:{\"direction\":\"DESC\",\"field\":\"last_message_sent_at\"})"
    },
    (v1/*: any*/)
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "802383902a4f1bd245e10cbf4bcd4d2f";

export default node;
