import { BrainParams } from "@/brain-search/internal/BrainSearchPage"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { GlobalID } from "@/relay/RelayTypes"
import { setSearchParams } from "@utils/url/urlUtils"
import { generatePath, useHistory } from "react-router-dom"

function useStartNewBrainSearch() {
  const history = useHistory()

  return (brainSearchId: GlobalID, initialPrompt: string) => {
    history.push({
      pathname: generatePath(ROUTE_NAMES.BRAIN.SEARCH, { searchId: brainSearchId }),
      search: setSearchParams<BrainParams>(location.search, {
        initialPrompt: encodeURIComponent(initialPrompt),
      }),
    })
  }
}

export default useStartNewBrainSearch
