import {
  MemberGroupVisibilityChipFragment$data,
  MemberGroupVisibilityChipFragment$key,
} from "@/admin/members/groups/__generated__/MemberGroupVisibilityChipFragment.graphql"
import { DiscoChip } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
import { graphql, useFragment } from "react-relay"

type Props = TestIDProps &
  (
    | {
        memberGroupKey: MemberGroupVisibilityChipFragment$key
      }
    | {
        memberGroup: Pick<MemberGroupVisibilityChipFragment$data, "visibility">
      }
  )

export default function MemberGroupVisibilityChip({
  testid = "MemberGroupVisibilityChip",
  ...optionalProps
}: Props) {
  const hasKey = "memberGroupKey" in optionalProps
  const hasGroup = "memberGroup" in optionalProps
  const group =
    useFragment<MemberGroupVisibilityChipFragment$key>(
      graphql`
        fragment MemberGroupVisibilityChipFragment on MemberGroup {
          visibility
        }
      `,
      hasKey ? optionalProps.memberGroupKey : null
    ) ?? (hasGroup ? optionalProps.memberGroup : null)

  if (!group) return null

  return (
    <DiscoChip
      testid={`${testid}.visibility`}
      label={group.visibility === "admin_only" ? "Admin Only" : "Everyone"}
      color={group.visibility === "admin_only" ? "orange" : "green"}
    />
  )
}
