/**
 * @generated SignedSource<<f2ea9cdf4e957355f802ad26dd7691fb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrganizationMembershipSlackConnectionStatus = "active" | "auth_error" | "%future added value";
export type AutomationSlackSendAsFieldsQuery$variables = {
  id: string;
};
export type AutomationSlackSendAsFieldsQuery$data = {
  readonly organization: {
    readonly omscs?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly organizationMembership: {
            readonly id: string;
            readonly user: {
              readonly avatar: string;
              readonly first_name: string | null;
              readonly id: string;
              readonly last_name: string | null;
              readonly " $fragmentSpreads": FragmentRefs<"ProfileAvatarFragment" | "ProfileAvatarWithDetailsFragment">;
            };
          };
          readonly status: OrganizationMembershipSlackConnectionStatus;
        };
      }>;
    };
    readonly viewerMembership?: {
      readonly id: string;
      readonly user: {
        readonly avatar: string;
        readonly first_name: string | null;
        readonly id: string;
        readonly last_name: string | null;
        readonly " $fragmentSpreads": FragmentRefs<"ProfileAvatarFragment" | "ProfileAvatarWithDetailsFragment">;
      };
    } | null;
  } | null;
};
export type AutomationSlackSendAsFieldsQuery = {
  response: AutomationSlackSendAsFieldsQuery$data;
  variables: AutomationSlackSendAsFieldsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": "first_name",
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v4 = {
  "alias": "last_name",
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "avatar",
  "storageKey": null
},
v6 = [
  (v2/*: any*/),
  {
    "alias": "user",
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "member",
    "plural": false,
    "selections": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ProfileAvatarWithDetailsFragment"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ProfileAvatarFragment"
      }
    ],
    "storageKey": null
  }
],
v7 = [
  {
    "kind": "Literal",
    "name": "status",
    "value": "active"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v9 = [
  (v2/*: any*/),
  {
    "alias": "user",
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "member",
    "plural": false,
    "selections": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "fullName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isTest",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AutomationSlackSendAsFieldsQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OrganizationMembership",
                "kind": "LinkedField",
                "name": "viewerMembership",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": null
              },
              {
                "alias": "omscs",
                "args": (v7/*: any*/),
                "concreteType": "OrganizationMembershipSlackConnectionNodeConnection",
                "kind": "LinkedField",
                "name": "organizationMembershipSlackConnections",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrganizationMembershipSlackConnectionNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrganizationMembershipSlackConnection",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "OrganizationMembership",
                            "kind": "LinkedField",
                            "name": "organizationMembership",
                            "plural": false,
                            "selections": (v6/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "organizationMembershipSlackConnections(status:\"active\")"
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AutomationSlackSendAsFieldsQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OrganizationMembership",
                "kind": "LinkedField",
                "name": "viewerMembership",
                "plural": false,
                "selections": (v9/*: any*/),
                "storageKey": null
              },
              {
                "alias": "omscs",
                "args": (v7/*: any*/),
                "concreteType": "OrganizationMembershipSlackConnectionNodeConnection",
                "kind": "LinkedField",
                "name": "organizationMembershipSlackConnections",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrganizationMembershipSlackConnectionNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrganizationMembershipSlackConnection",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "OrganizationMembership",
                            "kind": "LinkedField",
                            "name": "organizationMembership",
                            "plural": false,
                            "selections": (v9/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "organizationMembershipSlackConnections(status:\"active\")"
              }
            ],
            "type": "Organization",
            "abstractKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "61d9b50846657b5d71840b7ed7d0892c",
    "id": null,
    "metadata": {},
    "name": "AutomationSlackSendAsFieldsQuery",
    "operationKind": "query",
    "text": "query AutomationSlackSendAsFieldsQuery(\n  $id: ID!\n) {\n  organization: node(id: $id) {\n    __typename\n    ... on Organization {\n      viewerMembership {\n        id\n        user: member {\n          id\n          first_name: firstName\n          last_name: lastName\n          avatar\n          ...ProfileAvatarWithDetailsFragment\n          ...ProfileAvatarFragment\n        }\n      }\n      omscs: organizationMembershipSlackConnections(status: active) {\n        edges {\n          node {\n            id\n            status\n            organizationMembership {\n              id\n              user: member {\n                id\n                first_name: firstName\n                last_name: lastName\n                avatar\n                ...ProfileAvatarWithDetailsFragment\n                ...ProfileAvatarFragment\n              }\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment ProfileAvatarFragment on User {\n  id\n  fullName\n  avatar\n  isTest\n}\n\nfragment ProfileAvatarWithDetailsFragment on User {\n  id\n  fullName\n  ...ProfileAvatarFragment\n}\n"
  }
};
})();

(node as any).hash = "d511eab18ac767431098442cb49df232";

export default node;
