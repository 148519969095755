/**
 * @generated SignedSource<<0c273bd0ad001bbd62594dfbd138f04f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type SlackUserImportStatus = "cancelled" | "completed" | "failed" | "initial" | "inprogress" | "needsmapping" | "%future added value";
export type UpdateSlackUserImportInput = {
  id: string;
  status: SlackUserImportStatus;
};
export type SlackUserImportNeedsMappingStepContentMutation$variables = {
  input: UpdateSlackUserImportInput;
};
export type SlackUserImportNeedsMappingStepContentMutation$data = {
  readonly response: {
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
    readonly node: {
      readonly id: string;
    } | null;
  };
};
export type SlackUserImportNeedsMappingStepContentMutation = {
  response: SlackUserImportNeedsMappingStepContentMutation$data;
  variables: SlackUserImportNeedsMappingStepContentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": "response",
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateSlackUserImportResponse",
    "kind": "LinkedField",
    "name": "updateSlackUserImport",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SlackUserImport",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SlackUserImportNeedsMappingStepContentMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SlackUserImportNeedsMappingStepContentMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3115e9f776eb5cebc1936a6ce18a56d0",
    "id": null,
    "metadata": {},
    "name": "SlackUserImportNeedsMappingStepContentMutation",
    "operationKind": "mutation",
    "text": "mutation SlackUserImportNeedsMappingStepContentMutation(\n  $input: UpdateSlackUserImportInput!\n) {\n  response: updateSlackUserImport(input: $input) {\n    node {\n      id\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8da22b5b4a95245dd9d4ac755d66b87a";

export default node;
