/**
 * @generated SignedSource<<fc930e111af0337dd7b3fd470c3edd8a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type BadgeKind = "emoji" | "icon" | "upload" | "%future added value";
export type LabelKind = "admin_experience" | "admin_member" | "assignment" | "curriculum" | "custom" | "experience" | "lesson" | "organization_admin" | "organization_member" | "organization_owner" | "pathway" | "product_admin" | "product_instructor" | "product_member" | "quiz" | "sidebar_community" | "sidebar_product" | "%future added value";
export type OrganizationCheckoutVersion = "stripe" | "stripe_acacia" | "%future added value";
export type OrganizationCurrency = "aed" | "afn" | "all" | "amd" | "ang" | "aoa" | "ars" | "aud" | "awg" | "azn" | "bam" | "bbd" | "bdt" | "bgn" | "bmd" | "bnd" | "bob" | "brl" | "bsd" | "bwp" | "byn" | "bzd" | "cad" | "chf" | "cny" | "cop" | "crc" | "cve" | "czk" | "dkk" | "dop" | "dzd" | "egp" | "etb" | "eur" | "fjd" | "fkp" | "gbp" | "gel" | "gip" | "gmd" | "hkd" | "hnl" | "htg" | "huf" | "idr" | "ils" | "inr" | "isk" | "jmd" | "kgs" | "lak" | "lbp" | "lkr" | "lrd" | "lsl" | "mad" | "mdl" | "mkd" | "mmk" | "mnt" | "mop" | "mur" | "mvr" | "mxn" | "myr" | "mzn" | "nad" | "ngn" | "nio" | "nok" | "npr" | "nzd" | "pab" | "pen" | "pgk" | "php" | "pkr" | "pln" | "qar" | "ron" | "rsd" | "rub" | "sar" | "sbd" | "scr" | "sek" | "sgd" | "shp" | "srd" | "std" | "szl" | "thb" | "tjs" | "top" | "try" | "ttd" | "twd" | "uah" | "usd" | "uyu" | "uzs" | "zar" | "zmw" | "%future added value";
export type OrganizationVisibility = "private" | "public" | "%future added value";
export type ProductOrderBy = "dates" | "misconfigured_stripe_objects" | "name" | "nav" | "published" | "%future added value";
export type SearchOptOutEntity = "chat_channel" | "chat_message" | "content_usage" | "feed" | "occurrence" | "post" | "product" | "user" | "%future added value";
export type SearchOptOutRole = "admin" | "member" | "owner" | "%future added value";
export type ThemeKind = "custom" | "dark" | "light" | "%future added value";
export type UpdateOrganizationSettingsInput = {
  badge?: BadgeInput | null;
  billingContactEmail?: string | null;
  checkoutVersion?: OrganizationCheckoutVersion | null;
  cover?: string | null;
  coverAssetId?: string | null;
  currency?: OrganizationCurrency | null;
  darkLogo?: string | null;
  darkLogoAssetId?: string | null;
  description?: string | null;
  discoCompanyDomain?: string | null;
  experienceNavOrdering?: ReadonlyArray<string> | null;
  exploreProductTabSortBySetting?: string | null;
  faviconUrl?: string | null;
  id: string;
  isAskAIEnabled?: boolean | null;
  isChannelsEnabled?: boolean | null;
  isDmEnabled?: boolean | null;
  isEventsEnabled?: boolean | null;
  isExploreProductTabHidden?: boolean | null;
  isProductsEnabled?: boolean | null;
  label?: OrganizationSettingsLabelInput | null;
  logo?: string | null;
  logoAssetId?: string | null;
  name?: string | null;
  searchOptOuts?: ReadonlyArray<SearchOptOutInput> | null;
  showGlobalTabTitles?: boolean | null;
  slug?: string | null;
  theme?: OrganizationSettingsThemeInput | null;
  tracking?: UpdateTrackingInput | null;
  visibility?: OrganizationVisibility | null;
};
export type UpdateTrackingInput = {
  facebookPixelId?: string | null;
  googleAnalyticsId?: string | null;
  googleTagManagerId?: string | null;
};
export type BadgeInput = {
  assetId?: string | null;
  color?: string | null;
  emoji?: string | null;
  icon?: string | null;
  kind: BadgeKind;
  mediaUrl?: string | null;
};
export type OrganizationSettingsThemeInput = {
  backgroundColor?: string | null;
  cardBackgroundColor?: string | null;
  kind?: ThemeKind | null;
  navBackgroundColor?: string | null;
  primaryColor?: string | null;
  primaryContrastColor?: string | null;
};
export type OrganizationSettingsLabelInput = {
  labels: ReadonlyArray<UpdateLabelInput>;
};
export type UpdateLabelInput = {
  description?: string | null;
  kind: LabelKind;
  plural: string;
  singular: string;
};
export type SearchOptOutInput = {
  entity: SearchOptOutEntity;
  role: SearchOptOutRole;
};
export type ProductListSortDropdownMutation$variables = {
  input: UpdateOrganizationSettingsInput;
};
export type ProductListSortDropdownMutation$data = {
  readonly response: {
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
    readonly node: {
      readonly exploreProductTabSortBySetting: ProductOrderBy;
      readonly id: string;
    } | null;
  };
};
export type ProductListSortDropdownMutation = {
  response: ProductListSortDropdownMutation$data;
  variables: ProductListSortDropdownMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": "response",
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateOrganizationSettingsMutationResponse",
    "kind": "LinkedField",
    "name": "updateOrganizationSettings",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "exploreProductTabSortBySetting",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProductListSortDropdownMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProductListSortDropdownMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "46ab68aff99b40aa34064ce0d225083a",
    "id": null,
    "metadata": {},
    "name": "ProductListSortDropdownMutation",
    "operationKind": "mutation",
    "text": "mutation ProductListSortDropdownMutation(\n  $input: UpdateOrganizationSettingsInput!\n) {\n  response: updateOrganizationSettings(input: $input) {\n    node {\n      id\n      exploreProductTabSortBySetting\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "36f4763df862869f2bd410530ffa2535";

export default node;
