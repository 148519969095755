import CreatePathwayGroupsForm from "@/admin/pathways/CreatePathwayGroupsForm"
import { CreatePathwayFormInitialState } from "@/admin/pathways/hooks/useCreatePathwayForm"
import { PublishPathwayWarningModal } from "@/admin/pathways/PublishPathwayButton"
import { PathwaySettingsFormFragment$key } from "@/admin/pathways/settings/__generated__/PathwaySettingsFormFragment.graphql"
import {
  PathwaySettingsFormMutation,
  UpdatePathwayInput,
  UpdatePricingInput,
} from "@/admin/pathways/settings/__generated__/PathwaySettingsFormMutation.graphql"
import PathwaySettingsAvailability from "@/admin/pathways/settings/PathwaySettingsAvailability"
import PathwaySettingsDetails from "@/admin/pathways/settings/PathwaySettingsDetails"
import { useDrawerContext } from "@/core/context/DrawerContext"
import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import { useUnsavedChangesModalContext } from "@/core/context/UnsavedChangesModalProvider"
import FormStore, { useFormStore } from "@/core/form/store/FormStore"
import { formatUpdateMembershipBenefitInput } from "@/membership-benefit/membershipBenefitUtils"
import PricingFormFields from "@/pricing/PricingFormFields"
import { PricingTabType } from "@/product/settings/ExperienceSettingsForm"
import ProductUtils from "@/product/util/productUtils"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import Form from "@components/form/Form"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoButton } from "@disco-ui"
import useFeatureFlags from "@utils/hook/useFeatureFlags"
import { PickNonNullable, TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { useEffect, useMemo, useState } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

type PathwaySettingsFormState = PickNonNullable<UpdatePathwayInput, "pathwayGroups"> &
  Pick<CreatePathwayFormInitialState, "productCache"> & {
    pricingType: PricingTabType
    benefits: {
      id?: GlobalID | null
      membershipPlanId: GlobalID
      pricing: UpdatePricingInput
    }[]
  }

export type PathwaySettingsFormStore = FormStore<
  PathwaySettingsFormState,
  PathwaySettingsFormMutation
>

type Props = TestIDProps & {
  productKey: PathwaySettingsFormFragment$key
}

function PathwaySettingsForm(props: Props) {
  const { testid = "PathwaySettingsForm", productKey } = props
  const classes = useStyles()
  const drawer = useGlobalDrawer("pathwaySettings")
  const { closeDrawer } = useDrawerContext()
  const { setUnsavedChanges, handleLeave } = useUnsavedChangesModalContext()
  const [isPublishWarningOpen, setIsPublishWarningOpen] = useState(false)
  const { perPlanPricing } = useFeatureFlags()

  const product = useFragment<PathwaySettingsFormFragment$key>(
    graphql`
      fragment PathwaySettingsFormFragment on Product {
        id
        name
        slug
        description
        cover
        status
        registrationAvailability
        richEditorCheckoutDescription
        badge {
          kind
          icon
          color
          emoji
          mediaUrl
        }
        landingPage {
          mode
          metaTitle
          metaDescription
          metaImageUrl
        }
        childPathwayGroups {
          edges {
            node {
              id
              title
              products {
                edges {
                  node {
                    id
                    name
                    badge {
                      kind
                      icon
                      color
                      emoji
                      mediaUrl
                    }
                  }
                }
              }
            }
          }
        }
        includedInBenefits {
          edges {
            node {
              id
              pricing {
                amountCents
                frequency
                kind
              }
              membershipPlan {
                id
              }
            }
          }
        }
        ...ExperienceSettingsStatusRadioGroupFragment
        organization {
          ...useExperienceSettingsSelectedPlans_membershipPlansFragment
        }
      }
    `,
    productKey
  )

  // Build the pathway groups input and product cache from the current pathway groups
  const pathwayGroups = []
  const productCache: PathwaySettingsFormState["productCache"] = {}
  for (const g of Relay.connectionToArray(product.childPathwayGroups)) {
    const products = Relay.connectionToArray(g.products)
    pathwayGroups.push({
      id: g.id,
      title: g.title,
      productIds: products.map((p) => p.id),
    })
    for (const p of products) {
      productCache[p.id] = { name: p.name, badge: p.badge! }
    }
  }

  const benefits = Relay.connectionToArray(product.includedInBenefits).map((b) => ({
    id: b.id,
    membershipPlanId: b.membershipPlan.id,
    pricing: b.pricing,
  }))

  const pricingType = useMemo(
    () => getPricingType(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [benefits]
  )

  const form = useFormStore<PathwaySettingsFormMutation, PathwaySettingsFormState>(
    graphql`
      mutation PathwaySettingsFormMutation($input: UpdatePathwayInput!) {
        response: updatePathway(input: $input) {
          node {
            ...PathwaySettingsFormFragment
            ...ProductBadgeStackFragment
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      productId: product.id,
      name: product.name,
      slug: product.slug,
      description: product.description,
      cover: product.cover,
      status: product.status,
      registrationAvailability: product.registrationAvailability,
      richEditorCheckoutDescription: product.richEditorCheckoutDescription,
      badge: {
        ...product.badge,
        kind: product.badge?.kind || "icon",
      },
      landingPage: { ...product.landingPage },
      pathwayGroups,
      // Extra state that won't be submitted with the mutation
      productCache,
      benefits,
      pricingType,
    }
  )

  useEffect(() => {
    setUnsavedChanges(form.isChanged)
  }, [form.isChanged, setUnsavedChanges])

  if (!product) return null

  return (
    <Form
      testid={testid}
      classes={{ formFieldsContainer: classes.form }}
      onSubmit={handleSubmit}
      height={"100%"}
      hasStickyButtons
      buttons={
        <>
          <DiscoButton
            testid={`${testid}.cancel`}
            color={"grey"}
            variant={"outlined"}
            onClick={() => handleLeave({ onLeave: () => closeDrawer?.() })}
          >
            {"Cancel"}
          </DiscoButton>
          <Form.SubmitButton testid={`${testid}.submit`} form={form}>
            {"Save"}
          </Form.SubmitButton>
        </>
      }
    >
      {renderSettingsFormFields()}
      <PublishPathwayWarningModal
        testid={testid}
        isOpen={isPublishWarningOpen}
        onClose={() => setIsPublishWarningOpen(false)}
        handleSubmit={handleSubmit}
        disabled={form.disabled}
        isSubmitting={form.isSubmitting}
      />
    </Form>
  )

  function renderSettingsFormFields() {
    switch (drawer.params.pathwaySettingsTab) {
      case "availability":
        return (
          <PathwaySettingsAvailability
            form={form}
            organizationKey={product.organization}
          />
        )
      case "sequence":
        return <CreatePathwayGroupsForm form={form} />
      case "pricing":
        if (!perPlanPricing) return null
        return <PricingFormFields form={form} />
      case "details":
      default:
        return <PathwaySettingsDetails form={form} productKey={product} />
    }
  }

  function getPricingType() {
    const uniquePrices = new Set(benefits.map((b) => b.pricing.amountCents))
    if (uniquePrices.size === 1 && uniquePrices.has(0)) return "free"
    if (uniquePrices.size > 1) return "per-plan"
    return "paid"
  }

  async function handleSubmit() {
    const {
      productCache: _productCache,
      benefits: _benefits,
      pricingType: _pricingType,
      ...changedState
    } = form.changedState

    // If publishing the pathway, show a warning before submitting
    if (changedState.status === "published" && !isPublishWarningOpen) {
      setIsPublishWarningOpen(true)
      return
    }

    const updateBenefits = formatUpdateMembershipBenefitInput(form)

    const { didSave } = await form.submit(
      {
        productId: form.state.productId,
        ...changedState,
        ...("badge" in changedState && {
          badge: form.state.badge,
        }),
        ...(updateBenefits && { membershipBenefits: updateBenefits }),
      },
      {
        updater: (store) => {
          ProductUtils.invalidatePathwayProductRecord(store, product.id)

          // Reset the initial state of the benefits to the new state
          form.initialState.benefits = form.state.benefits
        },
      }
    )
    if (!didSave) return
    displaySuccessToast({
      testid: `${testid}.success`,
      message: "Pathway updated!",
    })
  }
}

const useStyles = makeUseStyles((theme) => ({
  form: {
    padding: theme.spacing(3, 3, 0),
  },
}))

export default observer(PathwaySettingsForm)
