import { BRAIN_SEARCH_PREVIEWING_APP_BAR_HEIGHT } from "@/admin/integrations/brain-search-bot/BrainSearchUtil"
import useBrainSearchBot from "@/admin/integrations/chat-bot/util/useBrainSearchBot"
import { useLabels } from "@/core/context/LabelsContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { DiscoButton, DiscoIcon, DiscoModal, DiscoText } from "@disco-ui"
import { useState } from "react"
import { useHistory } from "react-router-dom"

function BrainSearchPreviewingAppBar() {
  const brainSearchBot = useBrainSearchBot()
  const labels = useLabels()
  const classes = useStyles()
  const history = useHistory()
  const [isModalOpen, setIsModalOpen] = useState(false)
  if (!brainSearchBot || !brainSearchBot.isPreviewing) return null

  return (
    <>
      <div data-testid={"BrainSearchPreviewingAppBar"} className={classes.toolbarRoot}>
        <div className={classes.lhsContainer}>
          <DiscoIcon className={classes.icon} icon={"iconsax.custom-info"} />
          <DiscoText
            color={"common.white"}
          >{`You're viewing a preview of ${brainSearchBot.name}. ${labels.organization_member.plural} will not see this page until you enable ${brainSearchBot.name} in the settings.`}</DiscoText>
        </div>

        <DiscoButton
          testid={"BrainSearchPreviewingAppBar.turn-off-button"}
          color={"grey"}
          onClick={handleExitPreview}
        >
          {"Exit Preview"}
        </DiscoButton>
      </div>

      <DiscoModal
        isOpen={isModalOpen}
        onClose={closeModal}
        hideCloseIcon
        testid={"BrainSearchPreviewingAppBar.exit-preview-modal"}
        modalContentLabel={`Leave ${brainSearchBot.name} Preview`}
        maxWidth={"480px"}
        body={
          <div className={classes.modalTextContainer}>
            <DiscoText
              variant={"body-lg-700"}
            >{`Leave ${brainSearchBot.name} Preview`}</DiscoText>
            <DiscoText>{`Are you sure you want to stop previewing ${brainSearchBot.name}?`}</DiscoText>
          </div>
        }
        fullWidthButtons
        buttons={
          <div className={classes.modalButtonContainer}>
            <DiscoButton
              key={"cancel-button"}
              testid={"BrainSearchPreviewingAppBar.exit-preview-modal.cancel-button"}
              color={"grey"}
              variant={"outlined"}
              width={"100%"}
              onClick={closeModal}
            >
              {"Cancel"}
            </DiscoButton>
            <DiscoButton
              key={"exit-button"}
              testid={"BrainSearchPreviewingAppBar.exit-preview-modal.exit-button"}
              color={"primary"}
              width={"100%"}
              onClick={handleConfirmExitPreview}
            >
              {"Yes, leave now"}
            </DiscoButton>
          </div>
        }
      />
    </>
  )

  function closeModal() {
    setIsModalOpen(false)
  }

  function handleExitPreview() {
    setIsModalOpen(true)
  }

  function handleConfirmExitPreview() {
    closeModal()
    history.push({
      pathname: ROUTE_NAMES.ADMIN.AI.ROOT,
      search: "?isRedirect=1",
    })
  }
}

export default BrainSearchPreviewingAppBar

const useStyles = makeUseStyles((theme) => ({
  toolbarRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    padding: theme.spacing(2),
    gap: theme.spacing(1),
    minHeight: `${BRAIN_SEARCH_PREVIEWING_APP_BAR_HEIGHT}px`,
    background: theme.palette.aiGradient.bluePurple03,
  },
  lhsContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  icon: {
    color: theme.palette.common.white,
  },
  modalTextContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    textAlign: "center",
  },
  modalButtonContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    width: "100%",
  },
}))
