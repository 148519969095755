import { MemberGroupAvatarFragment$data } from "@/admin/members/groups/__generated__/MemberGroupAvatarFragment.graphql"
import useGetGroupName from "@/product/common/member-group/common/utils/useGetGroupName"
import { ProfileAvatarSize } from "@/user/common/avatar/ProfileAvatar"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { getAvatarBorderWidth } from "@components/avatar/Avatar"
import AvatarPlaceholder from "@components/avatar/placeholder/AvatarPlaceholder"
import DiscoImage from "@disco-ui/image/DiscoImage"
import { useTheme } from "@material-ui/core"
import { getInitialLettersOfWords } from "@utils/string/stringUtils"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import { graphql } from "relay-runtime"

interface MemberGroupAvatarProps extends TestIDProps {
  memberGroup: Omit<MemberGroupAvatarFragment$data, " $fragmentType">
  size?: ProfileAvatarSize
  showFullDetails?: boolean
  className?: string
}

function MemberGroupAvatar({
  memberGroup,
  testid = "MemberGroupAvatar",
  size = 96,
  showFullDetails = true,
  className,
}: MemberGroupAvatarProps) {
  const theme = useTheme()
  const hasAvatar = !!memberGroup.avatarURL
  const getName = useGetGroupName()

  const classes = useStyles({
    size,
  })

  return (
    <div
      data-testid={testid}
      className={classNames(
        classes.container,
        { [classes.border]: hasAvatar },
        className
      )}
    >
      {memberGroup.avatarURL ? (
        <DiscoImage testid={`${testid}.image`} src={memberGroup.avatarURL} />
      ) : (
        <AvatarPlaceholder
          testid={`${testid}.placeholder`}
          text={getAvatarText()}
          isTestUser={false}
          size={size}
          backgroundColor={memberGroup.color}
          color={theme.palette.getContrastText(memberGroup.color)}
        />
      )}
    </div>
  )

  function getAvatarText() {
    const name = getName(
      memberGroup.kind,
      memberGroup.name,
      showFullDetails,
      memberGroup.product ?? undefined,
      memberGroup.role
    )

    // We prepend role or "All" to the product name for product system groups, don't use in the avatar initials
    const skipFirstLetter =
      showFullDetails &&
      ["default", "role"].includes(memberGroup.kind) &&
      memberGroup.product

    return getInitialLettersOfWords(name).slice(
      skipFirstLetter ? 1 : 0,
      skipFirstLetter ? 3 : 2
    )
  }
}

type StyleProps = {
  size: ProfileAvatarSize
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    width: ({ size }: StyleProps) => size,
    height: ({ size }: StyleProps) => size,
    borderRadius: "50%",
    overflow: "hidden",
  },
  border: {
    border: ({ size }: StyleProps) =>
      `${getAvatarBorderWidth(size)}px solid ${theme.palette.common.white}`,
  },
}))

// eslint-disable-next-line no-unused-expressions
graphql`
  fragment MemberGroupAvatarFragment on MemberGroup {
    avatarURL
    color
    kind
    name
    product {
      id
      name
    }
    role
  }
`

export default MemberGroupAvatar
