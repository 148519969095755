import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoDrawer } from "@disco-ui"
import lazyWithRetry from "@utils/lazy/lazyWithRetry"

const IntegrationDrawerContent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "integration-drawer-content" */ "@/admin/integrations/drawer/IntegrationDrawerContent"
    )
)

export default function IntegrationDrawer() {
  const drawer = useGlobalDrawer("integration")
  const classes = useStyles()
  const { mode } = drawer.params
  const shouldRenderContent = Boolean(mode)

  return (
    <DiscoDrawer
      open={drawer.isOpen}
      size={"large"}
      containerClasses={{ drawerContainer: classes.drawerContainer }}
    >
      {shouldRenderContent && <IntegrationDrawerContent />}
    </DiscoDrawer>
  )
}

const useStyles = makeUseStyles({
  drawerContainer: {
    padding: 0,
  },
})
