/**
 * @generated SignedSource<<d57b137261d7ad2afd38a4c8af37b122>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type AutomationFilterLevel = "branch" | "step" | "trigger" | "%future added value";
export type AutomationStepActionKind = "add_to_group" | "add_to_pathway" | "add_to_product" | "add_to_slack_channel" | "release_certificate" | "remove_from_group" | "remove_from_pathway" | "remove_from_product" | "remove_from_slack_channel" | "send_chat_channel_message" | "send_chat_direct_message" | "send_email" | "send_slack_direct_message" | "%future added value";
export type AutomationStepKind = "action" | "branch" | "delay" | "%future added value";
export type AutomationStepWarningKind = "slack_channel_removed" | "%future added value";
export type MessageTemplateKind = "community_welcome" | "course_welcome" | "custom" | "product_completion" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type automationUtils_UpdateAutomationStepFragment$data = {
  readonly actionChannels: ReadonlyArray<string> | null;
  readonly actionKind: AutomationStepActionKind | null;
  readonly actionMemberGroupIds: ReadonlyArray<string> | null;
  readonly actionMessageTemplate: {
    readonly chatContent: {
      readonly richBody: string;
      readonly send: boolean;
      readonly sendAs: string | null;
    } | null;
    readonly emailContent: {
      readonly bccEmails: ReadonlyArray<string> | null;
      readonly bccUserIds: ReadonlyArray<string> | null;
      readonly ccEmails: ReadonlyArray<string> | null;
      readonly ccUserIds: ReadonlyArray<string> | null;
      readonly richBody: string;
      readonly send: boolean;
      readonly subject: string;
    } | null;
    readonly id: string;
    readonly kind: MessageTemplateKind | null;
    readonly name: string;
    readonly slackContent: {
      readonly richBody: string;
      readonly send: boolean;
      readonly sendAs: string | null;
    } | null;
  } | null;
  readonly actionProductIds: ReadonlyArray<string> | null;
  readonly delay: string | null;
  readonly filters: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly condition: string;
        readonly id: string;
        readonly level: AutomationFilterLevel;
      };
    }>;
  } | null;
  readonly id: string;
  readonly kind: AutomationStepKind;
  readonly warningKind: AutomationStepWarningKind | null;
  readonly " $fragmentType": "automationUtils_UpdateAutomationStepFragment";
};
export type automationUtils_UpdateAutomationStepFragment$key = {
  readonly " $data"?: automationUtils_UpdateAutomationStepFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"automationUtils_UpdateAutomationStepFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "send",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "richBody",
  "storageKey": null
},
v4 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "sendAs",
    "storageKey": null
  },
  (v3/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "automationUtils_UpdateAutomationStepFragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actionKind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "delay",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actionMemberGroupIds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actionChannels",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actionProductIds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "warningKind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AutomationFilterNodeConnection",
      "kind": "LinkedField",
      "name": "filters",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AutomationFilterNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AutomationFilter",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "level",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "condition",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MessageTemplate",
      "kind": "LinkedField",
      "name": "actionMessageTemplate",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "MessageTemplateEmailContent",
          "kind": "LinkedField",
          "name": "emailContent",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "subject",
              "storageKey": null
            },
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "ccEmails",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "bccEmails",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "ccUserIds",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "bccUserIds",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "MessageTemplateSlackContent",
          "kind": "LinkedField",
          "name": "slackContent",
          "plural": false,
          "selections": (v4/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "MessageTemplateChatContent",
          "kind": "LinkedField",
          "name": "chatContent",
          "plural": false,
          "selections": (v4/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AutomationStep",
  "abstractKey": null
};
})();

(node as any).hash = "3fb20859d70eabf10b226e70df52bc8e";

export default node;
