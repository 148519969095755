/**
 * @generated SignedSource<<5e01aaa031a21428af05e0999d41d618>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ProductAppKind = "chat_channel" | "collection" | "content" | "curriculum" | "events" | "group_directory" | "link" | "members" | "nav_folder" | "organization_events" | "organization_members" | "posts" | "product_link" | "resources" | "slack" | "%future added value";
export type ProductAppStatus = "active" | "inactive" | "%future added value";
export type ProductType = "community_event" | "course" | "membership_plan" | "pathway" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ToggleAppStatusButtonFragment$data = {
  readonly id: string;
  readonly kind: ProductAppKind;
  readonly product: {
    readonly type: ProductType;
  } | null;
  readonly status: ProductAppStatus;
  readonly " $fragmentType": "ToggleAppStatusButtonFragment";
};
export type ToggleAppStatusButtonFragment$key = {
  readonly " $data"?: ToggleAppStatusButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ToggleAppStatusButtonFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ToggleAppStatusButtonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "product",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ProductApp",
  "abstractKey": null
};

(node as any).hash = "a52ad09dc7ca1a504f29284464724ab9";

export default node;
