import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import UnsavedChangesModalProvider, {
  useInitUnsavedChangesModalContext,
} from "@/core/context/UnsavedChangesModalProvider"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import AIButton from "@components/ai/AIButton"
import { DiscoDrawer, DiscoDrawerHeader, DiscoText } from "@disco-ui"
import lazyWithRetry from "@utils/lazy/lazyWithRetry"
import { useHistory } from "react-router-dom"
import useBrainSearchBot from "../chat-bot/util/useBrainSearchBot"

const BrainSearchBotSettingsForm = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "ask-ai-bot-settings-form" */ "@/admin/integrations/brain-search-bot/BrainSearchBotSettingsForm"
    )
)

export default function BrainSearchBotSettingsDrawer() {
  const drawer = useGlobalDrawer("brainSearchBotSettings")
  const modal = useInitUnsavedChangesModalContext()
  const brainSearchBot = useBrainSearchBot()
  const classes = useStyles()
  const history = useHistory()

  if (!brainSearchBot) return null
  return (
    // If leaving with unsaved changes, display the unsaved changes modal
    <UnsavedChangesModalProvider {...modal}>
      <DiscoDrawer
        open={drawer.isOpen}
        onClose={handleCloseDrawer}
        size={"large"}
        containerClasses={{
          drawerContainer: classes.drawer,
        }}
      >
        <DiscoDrawerHeader
          title={
            <DiscoText
              variant={"heading-sm-600"}
            >{`${brainSearchBot.name} Settings`}</DiscoText>
          }
          onClose={handleCloseDrawer}
          className={classes.drawerHeader}
          disableExpand
          headerActions={
            brainSearchBot.status === "paused" && (
              <AIButton noIcon onClick={handlePreview}>
                {"Preview"}
              </AIButton>
            )
          }
        />

        {drawer.isOpen && <BrainSearchBotSettingsForm />}
      </DiscoDrawer>
    </UnsavedChangesModalProvider>
  )

  function handleCloseDrawer() {
    // Check that there are no unsaved changes before closing
    modal.handleLeave({
      onLeave: drawer.close,
    })
  }

  function handlePreview() {
    modal.handleLeave({
      onLeave: () => {
        history.push(ROUTE_NAMES.BRAIN.ROOT)
      },
    })
  }
}

const useStyles = makeUseStyles((theme) => ({
  drawerHeader: {
    padding: theme.spacing(2.5, 2.5, 0),
  },
  drawer: {
    padding: 0,
  },
}))
